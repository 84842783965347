// import React, { useState } from "react";
// import {
//   Container,
//   Grid,
//   Box,
//   Typography,
//   IconButton,
//   Menu,
//   MenuItem,
//   Card
// } from "@mui/material";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
// import NumberCard from "../../components/dashboard/NumberCard";
// import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,  } from '@mui/material';
// import courses from '../../data/home_dashboard.svg';
// import quizSvg from '../../data/quiz_dashboard.svg'
// import studentSvg from '../../data/student_dashboard.svg'
// import { useDispatch, useSelector } from 'react-redux'
// import {fetchDashboard} from '../../services/Operations/DashboardOperation'
// import { useEffect } from "react";
// import { useNavigate } from "react-router";
// import { apiConnector } from "../../services/ApiConnector";
// import {studentEndpoints,teacherEndpoints} from '../../services/ApiEndPoints'
// import {toast} from 'react-hot-toast'
// import { setMetrics } from "../../Store/Slices/dashboardSlice";

// const Dashboard = () => {
//   const navigate = useNavigate();
//   const token = useSelector((state) => state.auth.token);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [selectedUser, setSelectedUser] = useState(null);
//   const dispatch = useDispatch();
//   const { metrics } = useSelector((state) => state.dashboardData);

//   const handleClick = (event, user) => {
//     setAnchorEl(event.currentTarget);
//     setSelectedUser(user);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//     setSelectedUser(null);
//   };

  
//   useEffect(() => {
//         if (Object.keys(metrics).length === 0) {
//               dispatch(fetchDashboard(token));
//             }
//   }, []);
 

//   const handleDelete = async () => {
//     if(window.confirm("Are you Sur you want to delete")){
//     const toastId = toast.loading("Deleting User!");
  
//     try {
//       // Check the role of the selected user and determine the endpoint
//       const endpoint = selectedUser.role === "teacher"
//         ? `${teacherEndpoints.DELETE_TEACHER}/${selectedUser._id}`
//         : `${studentEndpoints.DELETE_STUDENT}/${selectedUser._id}`;
  
//       // Make the API call to delete the user
//       await apiConnector(
//         "DELETE",
//         endpoint,
//         {},
//         { Authorization: `Bearer ${token}` }
//       );
  
//       // Show success message
//       toast.success("User Deleted Successfully");
  
//       // Update the metrics to remove the deleted user
//       const updatedMetrics = {
//         ...metrics,
//         metrics: {
//           ...metrics.metrics,
//           topStudents: selectedUser.role === "student"
//             ? metrics?.metrics?.topStudents.filter((user) => user._id !== selectedUser._id)
//             : metrics.metrics.topStudents, // No change if it's a teacher
//           topTeachers: selectedUser.role === "teacher"
//             ? metrics?.metrics?.topTeachers.filter((user) => user._id !== selectedUser._id)
//             : metrics.metrics.topTeachers, // No change if it's a student
//         }
//       };
  
//       // Update the Redux store with the new metrics (if needed)
//       // If metrics are managed by Redux, you would dispatch an action here.
//       dispatch(setMetrics(updatedMetrics));
  
//     } catch (error) {
//       console.error(error);
//       toast.error("Failed to delete User");
//     } finally {
//       toast.dismiss(toastId);
//       handleClose(); // Close the menu after deletion
//     }
//   }
//   };
  
//   console.log(metrics)

//   return (
//     <>
//       <Container >
//         <Typography variant="h4" component="h1" fontWeight="600">
//           Dashboard
//         </Typography>
//         {/* Dashboard Items */}
//           <Grid item xs={12} mt={'20px'} sm={6} md={4} display={'flex'} width={'100%'} flexDirection={'row'} justifyContent={'start'} gap={'20px'} >
//             <NumberCard key={1} title={"Assessments"} number={metrics?.metrics?.totalSurveysCount} image={courses} textColor={'#FFFF'} bg={'#EF492E'} route={'/surveys'}/>
//             <NumberCard key={3} title={"Clients"} number={metrics?.metrics?.totalStudentsCount} image={studentSvg} textColor={'#EF492E'} bg={'#FFD9CD'} route={'/students'}/>
//           </Grid>
//       </Container>
//       <Container sx={{ my: 4 }}>
//         <Grid container spacing={4}>
//         {/* student */}
//           <Grid item xs={12} md={6} >
//             <Typography variant="h6" sx={{mb:'15px' , fontWeight:'600'}}>All Clients</Typography>
//             <TableContainer component={Paper}>
//               <Table>
//                 <TableHead >
//                   <TableRow sx={{backgroundColor:'#FFBE9D'}}>
//                     <TableCell><Typography   fontWeight="bold">Name</Typography></TableCell>
//                     <TableCell><Typography  fontWeight="bold">Email</Typography></TableCell>
//                     <TableCell><Typography fontWeight="bold">Actions</Typography></TableCell>
//                   </TableRow>
//                 </TableHead>
//                 <TableBody>
//                   {metrics?.metrics?.topStudents?.map((student) => (
//                     <TableRow key={student.id}>
//                       <TableCell >{student.name}</TableCell>
//                       <TableCell >{student.email}</TableCell>
//                       <TableCell>
//                         <IconButton onClick={(event) => handleClick(event, student)}>
//                           <MoreVertIcon />
//                         </IconButton>
//                       </TableCell>
//                     </TableRow>
//                   ))}
//                 </TableBody>
//               </Table>
//             </TableContainer>
//           </Grid>
//           {/* Teacher */}
//           <Grid item xs={12} md={6}>
//             <Typography variant="h6" sx={{mb:'15px'  , fontWeight:'600'}}>All Assessments</Typography>
//             <TableContainer component={Paper}>
//               <Table>
//                 <TableHead>
//                   <TableRow sx={{backgroundColor:'#FFBE9D'}}>
//                     <TableCell><Typography  fontWeight="bold">Name</Typography></TableCell>
//                     <TableCell><Typography  fontWeight="bold">Duration</Typography></TableCell>
//                     <TableCell><Typography  fontWeight="bold">passing Marks</Typography></TableCell>
//                     <TableCell><Typography  fontWeight="bold">Actions</Typography></TableCell>
//                   </TableRow>
//                 </TableHead>
//                 <TableBody>
//                 {metrics?.metrics?.topSurvey?.map((survey,index) => (
//                     <TableRow key={survey._id}>
//                       <TableCell >{survey.name}</TableCell>
//                       <TableCell >{survey.duration}</TableCell>
//                       <TableCell >{survey.passingMark}</TableCell>
//                       <TableCell>
//                         <IconButton onClick={(event) => handleClick(event, survey)}>
//                           <MoreVertIcon />
//                         </IconButton>
//                       </TableCell>
//                     </TableRow>
//                   ))}
//                 </TableBody>
//               </Table>
//             </TableContainer>
//           </Grid>
//         </Grid>

//         <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
//           <MenuItem onClick={() => {
//              navigate(`/studentprofile/${selectedUser._id}`);
//           }}>View</MenuItem>
//           <MenuItem onClick={handleDelete}>Delete</MenuItem>
//         </Menu>

//       </Container>
//     </>
//   );
// };

// export default Dashboard;

import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MoreVertIcon from '@mui/icons-material/MoreVert'; // Import MoreVertIcon for verticon
import NumberCard from "../../components/dashboard/NumberCard";
import courses from '../../data/home_dashboard.svg';
import studentSvg from '../../data/student_dashboard.svg';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDashboard } from '../../services/Operations/DashboardOperation';
import { useNavigate } from "react-router";
import { apiConnector } from "../../services/ApiConnector";
import { studentEndpoints, surveyEndpoints } from '../../services/ApiEndPoints';
import { toast } from 'react-hot-toast';
import { setMetrics } from "../../Store/Slices/dashboardSlice";

const Dashboard = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  
  const [clientAnchorEl, setClientAnchorEl] = useState(null);  // For client menu control
  const [surveyAnchorEl, setSurveyAnchorEl] = useState(null);  // For survey menu control
  const [selectedClient, setSelectedClient] = useState(null);  // Selected client
  const [selectedSurvey, setSelectedSurvey] = useState(null);  // Selected survey
  
  const dispatch = useDispatch();
  const { metrics } = useSelector((state) => state.dashboardData);

  useEffect(() => {
    if (Object.keys(metrics).length === 0) {
      dispatch(fetchDashboard(token));
    }
  }, []);

  console.log(metrics)

  // Function handling the deletion of both survey/clients
  const handleDelete = async (item, type) => {
    handleClientMenuClose();
    handleSurveyMenuClose();
    if (window.confirm("Are you sure you want to delete?")) {
      const toastId = toast.loading("Deleting...");
      try {
        const endpoint = type === "client"
          ? `${studentEndpoints.DELETE_STUDENT}/${item._id}`
          : `${surveyEndpoints.DELETE_SURVEY}/${item._id}`;
  
       const response = await apiConnector(
          "DELETE",
          endpoint,
          {},
          { Authorization: `Bearer ${token}` }
        );

        console.log(response)
  
        toast.success("Deleted Successfully");
        const updatedMetrics = {
          ...metrics,
          metrics: {
            ...metrics.metrics,
            topStudents: type === "client"
              ? metrics?.metrics?.topStudents.filter((u) => u._id !== item._id)
              : metrics.metrics.topStudents,
            topSurvey: type === "survey"
              ? metrics?.metrics?.topSurvey.filter((u) => u._id !== item._id)
              : metrics.metrics.topSurvey,
          }
        };
        dispatch(setMetrics(updatedMetrics));
      } catch (error) {
        console.error(error);
        
        toast.error("Failed to delete");
      } finally {
        toast.dismiss(toastId);
      }
    }
  };

  // Client menu handlers
  const handleClientMenuOpen = (event, client) => {
    setClientAnchorEl(event.currentTarget);  // Open client menu
    setSelectedClient(client);  // Set selected client
  };
  const handleClientMenuClose = () => {
    setClientAnchorEl(null);  // Close client menu
    setSelectedClient(null);  // Clear selected client
  };
  const handleClientView = () => {
    navigate(`/studentprofile/${selectedClient._id}`);
    handleClientMenuClose();
  };

  // Survey menu handlers
  const handleSurveyMenuOpen = (event, survey) => {
    setSurveyAnchorEl(event.currentTarget);  // Open survey menu
    setSelectedSurvey(survey);  // Set selected survey
  };
  const handleSurveyMenuClose = () => {
    setSurveyAnchorEl(null);  // Close survey menu
    setSelectedSurvey(null);  // Clear selected survey
  };
  const handleSurveyView = () => {
    navigate(`/survey/${selectedSurvey._id}`);
    handleSurveyMenuClose();
  };

  return (
    <>
      <Container>
        <Typography variant="h4" component="h1" fontWeight="600">
          Dashboard
        </Typography>
        <Grid item xs={12} mt={'20px'} sm={6} md={4} display={'flex'} width={'100%'} flexDirection={'row'} justifyContent={'start'} gap={'20px'}>
          <NumberCard key={1} title={"Assessments"} number={metrics?.metrics?.totalSurveysCount} image={courses} textColor={'#FFFF'} bg={'#EF492E'} route={'/surveys'} />
          <NumberCard key={3} title={"Clients"} number={metrics?.metrics?.totalStudentsCount} image={studentSvg} textColor={'#EF492E'} bg={'#FFD9CD'} route={'/students'} />
        </Grid>
      </Container>

      <Container sx={{ my: 4 }}>
        <Grid container spacing={4}>
          {/* Clients */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6" sx={{ mb: '15px', fontWeight: '600' }}>All Clients</Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: '#FFBE9D' }}>
                    <TableCell><Typography fontWeight="bold">Name</Typography></TableCell>
                    <TableCell><Typography fontWeight="bold">Email</Typography></TableCell>
                    <TableCell><Typography fontWeight="bold">Actions</Typography></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {metrics?.metrics?.topStudents?.map((student) => (
                    <TableRow key={student._id}>
                      <TableCell>{student.name}</TableCell>
                      <TableCell>{student.email}</TableCell>
                      <TableCell align="center">
                        {/* Verticon menu for clients */}
                        <IconButton onClick={(event) => handleClientMenuOpen(event, student)}>
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          anchorEl={clientAnchorEl}
                          open={Boolean(clientAnchorEl)}
                          onClose={handleClientMenuClose}
                        >
                          <MenuItem onClick={handleClientView}>
                            <VisibilityIcon fontSize="small" /> View
                          </MenuItem>
                          <MenuItem onClick={() => handleDelete(selectedClient, "client")}>
                            <DeleteIcon fontSize="small" /> Delete
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          {/* Surveys */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6" sx={{ mb: '15px', fontWeight: '600' }}>All Assessments</Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: '#FFBE9D' }}>
                    <TableCell><Typography fontWeight="bold">Name</Typography></TableCell>
                    <TableCell><Typography fontWeight="bold">Duration</Typography></TableCell>
                    <TableCell><Typography fontWeight="bold">Passing Marks</Typography></TableCell>
                    <TableCell><Typography fontWeight="bold">Actions</Typography></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {metrics?.metrics?.topSurvey?.map((survey) => (
                    <TableRow key={survey._id}>
                      <TableCell>{survey.name}</TableCell>
                      <TableCell>{survey.duration}</TableCell>
                      <TableCell>{survey.passingMark}</TableCell>
                      <TableCell align="center">
                        {/* Verticon menu for surveys */}
                        <IconButton onClick={(event) => handleSurveyMenuOpen(event, survey)}>
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          anchorEl={surveyAnchorEl}
                          open={Boolean(surveyAnchorEl)}
                          onClose={handleSurveyMenuClose}
                        >
                          <MenuItem onClick={handleSurveyView}>
                            <VisibilityIcon fontSize="small" /> View
                          </MenuItem>
                          <MenuItem onClick={() => handleDelete(selectedSurvey, "survey")}>
                            <DeleteIcon fontSize="small" /> Delete
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

        </Grid>
      </Container>
    </>
  );
};

export default Dashboard;
