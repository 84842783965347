import React, { useState } from 'react';
import {
  Box,
  Typography,
  Checkbox,
  Radio,
  TextField,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Paper,
} from '@mui/material';
import { Rating } from '@mui/material'; // Make sure to import Rating
import toast from 'react-hot-toast';

const SurveyQuestion = ({ index, question, responses, setResponses }) => {
  const [wordCount, setWordCount] = useState(0);

  const handleChange = (id, value) => {
    setResponses((prevResponses) => ({
      ...prevResponses,
      [id]: prevResponses[id] === value ? null : value, // Deselect if clicked again
    }));
  };

  const handleChangeMultiple = (id, value) => {
    setResponses((prevResponses) => {
      const currentResponse = prevResponses[id] || [];
      const newResponse = currentResponse.includes(value)
        ? currentResponse.filter((v) => v !== value) // Remove if already selected
        : [...currentResponse, value]; // Add if not selected

      return {
        ...prevResponses,
        [id]: newResponse.length > 0 ? newResponse : null, // Set to null if no selection
      };
    });
  };

  return (
    <Paper
      elevation={3}
      sx={{
        mb: 4,
        p: 3,
        borderRadius: 2,
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        width: '70%',
        mx: 'auto',
      }}
    >
      <Typography variant="h6" fontWeight="bold" gutterBottom>
        Question {index + 1}: {question.title} {question?.required ? '***' : ''}
      </Typography>

      {/* Multiple-choice */}
      {question.type === 'Multiple-choice' && (
        <FormControl component="fieldset">
          <Box display="flex" flexWrap="wrap" gap={2}>
            {question?.options.map((option, idx) => (
              <FormControlLabel
                key={idx}
                control={
                  <Checkbox
                    checked={responses[question?._id]?.includes(option?.value)}
                    onChange={() => handleChangeMultiple(question?._id, option?.value)}
                    name={`question-${question?._id}`}
                  />
                }
                label={option?.value}
                sx={{ flexBasis: '45%' }}
              />
            ))}
          </Box>
        </FormControl>
      )}

      {/* Single-choice */}
      {question.type === 'Single-choice' && (
        <FormControl component="fieldset">
          <RadioGroup>
            {question.options.map((option, idx) => (
              <FormControlLabel
                key={idx}
                control={
                  <Radio
                    onClick={() => {
                      const isChecked = responses[question?._id] === option?.value;
                      handleChange(question?._id, isChecked ? null : option?.value); // Deselect if already checked
                    }}
                    checked={responses[question?._id] === option?.value}
                    name={`question-${question?._id}`}
                    required={question.required}
                  />
                }
                label={option?.value}
                sx={{ flexBasis: '45%' }}
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}

      {/* Rating */}
      {question.type === 'Rating' && (
        <Box mt={2}>
          <Typography variant="body1" gutterBottom>
            Rate this question:
          </Typography>
          <Rating
            name={`rating-${question?._id}`}
            value={responses[question?._id] || 0}
            onChange={(event, newValue) => handleChange(question?._id, newValue)}
            precision={1}
            size="large"
          />
        </Box>
      )}

      {/* Agree/Disagree */}
      {question.type === 'Agree-disagree' && (
        <Box mt={2}>
          <FormControlLabel
            control={
              <Radio
                onClick={() => {
                  const isChecked = responses[question?._id] === 'Agree';
                  handleChange(question?._id, isChecked ? null : 'Agree');
                }}
                checked={responses[question?._id] === 'Agree'}
                name={`question-${question?._id}`}
                required={question.required}
              />
            }
            label="Agree"
          />
          <FormControlLabel
            control={
              <Radio
                onClick={() => {
                  const isChecked = responses[question?._id] === 'Disagree';
                  handleChange(question?._id, isChecked ? null : 'Disagree');
                }}
                checked={responses[question?._id] === 'Disagree'}
                name={`question-${question?._id}`}
                required={question.required}
              />
            }
            label="Disagree"
          />
        </Box>
      )}

      {/* Short Answer */}
      {question.type === 'Short-answer' && (
        <TextField
          multiline
          minRows={3}
          variant="outlined"
          fullWidth
          onChange={(e) => handleChange(question?._id, e.target.value)}
          placeholder="Write your answer here"
          required={question.required}
          sx={{ mt: 2 }}
        />
      )}

      {/* Brief Answer */}
      {question.type === 'Brief-answer' && (
        <Box mt={2}>
          <TextField
            multiline
            variant="outlined"
            fullWidth
            value={responses[question?._id]}
            onChange={(e) => {
              const value = e.target.value;
              const trimmedValue = value.trim();
              const wordCount = trimmedValue.length > 0 ? trimmedValue.split(/\s+/).length : 0;

              if (wordCount <= (question.ansLength || 100000) || e.nativeEvent.inputType === 'deleteContentBackward') {
                handleChange(question?._id, value); // Handle input change
                setWordCount(wordCount); // Set real-time word count
              } else {
                toast.error("Word Limit is Exceeding!");
              }
            }}
            placeholder="Write brief answer here"
            required={question.required}
          />
          <Typography variant="caption" color="text.secondary">
            Word count: {wordCount}/{question.ansLength || 100000}
          </Typography>
        </Box>
      )}
    </Paper>
  );
};

export default SurveyQuestion;
