import React from 'react';
import { Box, Typography, Paper, Divider, Container } from '@mui/material';

const SurveyDetailCard = ({ questionCount, surveyDetail }) => {
  const { name, description, startDate, endDate, type, courseId } = surveyDetail || {};

  return (
    <Container
      component={Paper}
      elevation={3}
      sx={{
        p: 4,
        
        width: '90%',
        mx: 'auto',
        borderRadius: 2,
        backgroundColor: '#f9f9f9',
      }}
    >
      <Box display="flex" flexDirection="column" gap={2}>
        {/* Survey Details */}
        <Typography variant="h4" component="h2" fontWeight="bold" sx={{ mb: 1 }}>
          {name}
        </Typography>
        <Typography variant="body1" fontWeight={'500'}   sx={{ mb: 2,color:'gray' }}>
          {description}
        </Typography>

        <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} gap={2}>
          <Typography variant="body2" color="text.secondary">
            <strong>Course:</strong> {courseId?.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <strong>Type:</strong> {type}
          </Typography>
        </Box>

        <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
          <strong>Number of Questions:</strong> {questionCount}
        </Typography>

        {/* <Divider sx={{ my: 3 }} /> */}

        {/* <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} justifyContent="space-between">
          <Typography variant="body2" color="text.secondary">
            <strong>Start Date:</strong> {new Date(startDate).toLocaleDateString()}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <strong>End Date:</strong> {new Date(endDate).toLocaleDateString()}
          </Typography>
        </Box> */}
      </Box>
    </Container>
  );
};

export default SurveyDetailCard;
