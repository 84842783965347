import { toast } from "react-hot-toast";
import { apiConnector } from '../ApiConnector';
import { studentEndpoints } from '../ApiEndPoints';
import { setStudents, appendStudents, setLoading, clearStudents,  setSearchTerm } from '../../Store/Slices/studentSlice';

export function fetchStudents(token, page, limit, searchTerm = '',  reset = false) {
  return async (dispatch, getState) => {
    const { searchTerm: previousSearchTerm } = getState().students;

    if (reset || searchTerm !== previousSearchTerm ) {
      dispatch(clearStudents());
      dispatch(setSearchTerm(searchTerm));
    }

    const toastId = toast.loading("Loading students...");
    dispatch(setLoading(true));

    try {
      const response = await apiConnector(
        "GET",
        `${studentEndpoints.ALL_STUDENTS_DATA_API}?page=${page}&perPage=${limit}&search=${searchTerm}`,
        null,
        { Authorization: `Bearer ${token}` }
      );
     
      if (response.status !== 200) {
        throw new Error("Could not fetch student data");
      }

      console.log(response)

      const result = response?.data?.data;
      const totalStudents = response.data.totalCounceller;
      const totalPages = response.data.totalPages;

      if (reset || searchTerm !== previousSearchTerm  || page === 1) {
        dispatch(setStudents({ students: result, totalStudents, totalPages, currentPage: page,  searchTerm }));
      } else {
        dispatch(appendStudents({ students: result, totalStudents, totalPages, currentPage: page,  searchTerm }));
      }

      toast.success("Students fetched successfully");
    } catch (error) {
      toast.error(`Error! ${error?.response?.data?.message}`)
    } finally {
      dispatch(setLoading(false));
      toast.dismiss(toastId);
    }
  };
}
