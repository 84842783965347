// import React, { useEffect, useState } from "react";
// import {
//   Drawer,
//   List,
//   ListItemButton,
//   ListItemIcon,
//   ListItemText,
//   Divider,
//   Box,
// } from "@mui/material";
// import {
//   Home as HomeIcon,
//   School as SchoolIcon,
//   People as PeopleIcon,
//   GroupAdd as GroupAddIcon,
//   Notifications as NotificationsIcon,
//   ExitToApp as ExitToAppIcon,
//   Quiz as QuizIcon,
//   LibraryBooks as LibraryBooksIcon,
// } from "@mui/icons-material";
// import FitbitIcon from '@mui/icons-material/Fitbit';
// import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
// import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
// import Logo from "../data/Logo.png";
// import { useNavigate } from "react-router-dom";

// import { useDispatch, useSelector } from 'react-redux';
// import { logout } from "../services/Operations/AuthOperations";

// const Sidebar = () => {
//   const brandLogo = useSelector((state) => state.profile?.user?.brandId?.logo);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const [activeElem, setActiveElem] = useState('/');
//   const [accessFunctionalities,setAccessFunctionalities] = useState([]);
  
  
//   const profileState = useSelector((state) => state.profile);
//   const user = profileState.user;

//   useEffect(() => {
//     if (user) {
//       setAccessFunctionalities(user?.permission || [])
//     }
//   }, [user]); 

//   // Define all available menu items with their corresponding permissions
//   const menuItems = [
//     { text: "Dashboard", icon: <HomeIcon />, path: '/', permission: null }, // Accessible to everyone
//     { text: "Assigned Courses", icon: <LibraryBooksIcon />, path: '/courses', permission: null },
//     { text: "Assigned Assessments", icon: <SchoolIcon />, path: '/assigned-survey', permission: null },
//     { text: "Client Assessments", icon: <SchoolIcon />, path: '/surveys', permission: "assessment-management" },
//     { text: "All Clients", icon: <PeopleIcon />, path: '/students', permission: "student-management" },
//     { text: "Client Reports", icon: <QuizIcon />, path: '/reports', permission: "report-management" },
//     { text: "Assigned Reports", icon: <QuizIcon />, path: '/assigned-reports', permission: null },
//     // { text: "Teachers", icon: <GroupAddIcon />, path: '/teachers', permission: "teacher-management" },
//     // { text: "Brands", icon: <FitbitIcon />, path: '/brands', permission: "brand-management" },
//     // { text: "Schools", icon: <SchoolIcon />, path: '/schools', permission: "school-management" },
//     { text: "Certificates", icon: <WorkspacePremiumIcon />, path: '/certifications', permission: null },
//     { text: "Notifications", icon: <NotificationsIcon />, path: '/notifications', permission: null }, // Accessible to everyone
//   ];

//   // Filter menu items based on user permissions
//   const filteredMenuItems = menuItems.filter(item => {
//     return item.permission === null || accessFunctionalities.includes(item.permission);
//   });

//   const handleSignOut = async () => {
//     if (window.confirm("Are you sure you want to log out?")) {
//       dispatch(logout(navigate));
//     }
//   };

//   return (
//     <Drawer
//       variant="permanent"
//       anchor="left"
//       sx={{
//         width: 240,
//         flexShrink: 0,
//         "& .MuiDrawer-paper": {
//           width: 240,
//           backgroundColor: "#1D2026",
//           color: "#fff",
//         },
//       }}
//     >
//       <Box display="flex" flexDirection="column" height="100%">
//         <Box display="flex" justifyContent="center" alignItems="center">
//           <img
//             src={brandLogo}
//             alt={"brand logo"}
//             style={{ maxWidth: "100%", height: "50px", marginTop: '2px' }}
//           />
//         </Box>
//         <Divider />
//         <List>
//           {filteredMenuItems.map((item, index) => (
//             <ListItemButton
//               key={index}
//               onClick={() => {
//                 navigate(item.path);
//                 setActiveElem(item.path);
//               }}
//               sx={{
//                 backgroundColor: activeElem === item.path ? '#FF6636' : 'transparent', // Background for active state
//                 "&:hover": {
//                   backgroundColor: activeElem === item.path ? '#FF6636' : '#FF6636', // Background on hover
//                   color: "#fff", // Text color on hover
//                 },
//               }}
//             >
//               <ListItemIcon sx={{ color: "inherit" }}>
//                 {item.icon}
//               </ListItemIcon>
//               <ListItemText primary={item.text} />
//             </ListItemButton>
//           ))}
//           {/* Signout button  */}
//           <ListItemButton
//             onClick={handleSignOut}
//             sx={{
//               font: 'bold',
//               backgroundColor: 'white',
//               color: '#EF492E',
//               "&:hover": {
//                 backgroundColor: "#FF6636",
//                 color: "#fff",
//               },
//             }}
//           >
//             <ListItemIcon sx={{ color: "inherit" }}>
//               <ExitToAppIcon />
//             </ListItemIcon>
//             <ListItemText primary={"Sign Out"} />
//           </ListItemButton>
//         </List>
//       </Box>
//     </Drawer>
//   );
// };

// export default Sidebar;


import React, { useEffect, useState } from "react";
import {
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Box,
  Collapse,
} from "@mui/material";
import {
  Home as HomeIcon,
  People as PeopleIcon,
  Notifications as NotificationsIcon,
  ExitToApp as ExitToAppIcon,
  School as SchoolIcon,
  Quiz as QuizIcon,
  LibraryBooks as LibraryBooksIcon,
  WorkspacePremium as WorkspacePremiumIcon,
} from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../services/Operations/AuthOperations";

const Sidebar = () => {
  const brandLogo = useSelector((state) => state.profile?.user?.brandId?.logo);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeElem, setActiveElem] = useState("/");
  const [accessFunctionalities, setAccessFunctionalities] = useState([]);
  const [isClientAccordionOpen, setClientAccordionOpen] = useState(false);
  const [isResourceAccordionOpen, setResourceAccordionOpen] = useState(false);

  const profileState = useSelector((state) => state.profile);
  const user = profileState.user;

  useEffect(() => {
    if (user) {
      setAccessFunctionalities(user?.permission || []);
    }
  }, [user]);

  const menuItems = [
    { text: "Dashboard", icon: <HomeIcon />, path: "/", permission: null },
  ];

  const clientMenuItems = [
    { text: "All Clients", icon: <PeopleIcon />, path: "/students", permission: "student-management" },
    { text: "Client's Assessments", icon: <SchoolIcon />, path: "/surveys", permission: "assessment-management" },
    { text: "Client's Reports", icon: <QuizIcon />, path: "/reports", permission: "report-management" },
  ];

  const resourceMenuItems = [
    { text: "Courses", icon: <LibraryBooksIcon />, path: "/courses", permission: null },
    { text: "Assessments", icon: <SchoolIcon />, path: "/assigned-survey", permission: null },
    { text: "Reports", icon: <QuizIcon />, path: "/assigned-reports", permission: null },
    { text: "Certificates", icon: <WorkspacePremiumIcon />, path: "/certifications", permission: null },
  ];

  const filteredMenuItems = menuItems.filter((item) => item.permission === null || accessFunctionalities.includes(item.permission));
  const filteredClientMenuItems = clientMenuItems.filter((item) => accessFunctionalities.includes(item.permission));
  const filteredResourceMenuItems = resourceMenuItems.filter((item) => item.permission === null || accessFunctionalities.includes(item.permission));

  const handleSignOut = async () => {
    if (window.confirm("Are you sure you want to log out?")) {
      dispatch(logout(navigate));
    }
  };

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      sx={{
        width: 240,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: 240,
          backgroundColor: "#1D2026",
          color: "#fff",
        },
      }}
    >
      <Box display="flex" flexDirection="column" height="100%">
        <Box display="flex" justifyContent="center" alignItems="center">
          <img
            src={brandLogo}
            alt={"brand logo"}
            style={{ maxWidth: "100%", height: "50px", marginTop: "2px" }}
          />
        </Box>
        <Divider />
        <List>
          {filteredMenuItems.map((item, index) => (
            <ListItemButton
              key={index}
              onClick={() => {
                navigate(item.path);
                setActiveElem(item.path);
              }}
              sx={{
                backgroundColor: activeElem === item.path ? "#FF6636" : "transparent",
                "&:hover": {
                  backgroundColor: "#FF6636",
                  color: "#fff",
                },
              }}
            >
              <ListItemIcon sx={{ color: "inherit" }}>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          ))}

          
          {/* Accordion for My Resources */}
          <ListItemButton
            onClick={() => setResourceAccordionOpen(!isResourceAccordionOpen)}
            sx={{
              "&:hover": {
                backgroundColor: "#FF6636",
                color: "#fff",
              },
            }}
          >
            <ListItemIcon sx={{ color: "inherit" }}>
              <LibraryBooksIcon />
            </ListItemIcon>
            <ListItemText primary="My Resources" />
            {isResourceAccordionOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItemButton>
          <Collapse in={isResourceAccordionOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {filteredResourceMenuItems.map((item, index) => (
                <ListItemButton
                  key={index}
                  onClick={() => {
                    navigate(item.path);
                    setActiveElem(item.path);
                  }}
                  sx={{
                    pl: 4,
                    backgroundColor: activeElem === item.path ? "#FF6636" : "transparent",
                    "&:hover": {
                      backgroundColor: "#FF6636",
                      color: "#fff",
                    },
                  }}
                >
                  <ListItemIcon sx={{ color: "inherit" }}>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>


          {/* Accordion for Client Menu Items */}
          <ListItemButton
            onClick={() => setClientAccordionOpen(!isClientAccordionOpen)}
            sx={{
              "&:hover": {
                backgroundColor: "#FF6636",
                color: "#fff",
              },
            }}
          >
            <ListItemIcon sx={{ color: "inherit" }}>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Client Functions" />
            {isClientAccordionOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItemButton>
          <Collapse in={isClientAccordionOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {filteredClientMenuItems.map((item, index) => (
                <ListItemButton
                  key={index}
                  onClick={() => {
                    navigate(item.path);
                    setActiveElem(item.path);
                  }}
                  sx={{
                    pl: 4,
                    backgroundColor: activeElem === item.path ? "#FF6636" : "transparent",
                    "&:hover": {
                      backgroundColor: "#FF6636",
                      color: "#fff",
                    },
                  }}
                >
                  <ListItemIcon sx={{ color: "inherit" }}>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>

          {/* Notifications at the bottom */}
          <Divider />
          <ListItemButton
            onClick={() => {
              navigate("/notifications");
              setActiveElem("/notifications");
            }}
            sx={{
              backgroundColor: activeElem === "/notifications" ? "#FF6636" : "transparent",
              "&:hover": {
                backgroundColor: "#FF6636",
                color: "#fff",
              },
            }}
          >
            <ListItemIcon sx={{ color: "inherit" }}>
              <NotificationsIcon />
            </ListItemIcon>
            <ListItemText primary="Notifications" />
          </ListItemButton>

          {/* Sign Out Button */}
          <ListItemButton
            onClick={handleSignOut}
            sx={{
              font: "bold",
              backgroundColor: "white",
              color: "#EF492E",
              "&:hover": {
                backgroundColor: "#FF6636",
                color: "#fff",
              },
            }}
          >
            <ListItemIcon sx={{ color: "inherit" }}>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary={"Sign Out"} />
          </ListItemButton>
        </List>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
