import { toast } from "react-hot-toast";
import { apiConnector } from '../ApiConnector';
import { surveyEndpoints } from '../ApiEndPoints';
import { setSurveys, appendSurveys, setLoading, clearSurveys, setSearchTerm } from '../../Store/Slices/surveySlice';

export function fetchSurveys(questionnaireType, token, page, limit, searchTerm, reset = false) {
  return async (dispatch, getState) => {
    const user = getState().profile?.user;
    const { searchTerm: previousSearchTerm } = getState().surveys;

    if (reset || searchTerm !== previousSearchTerm) {
      dispatch(clearSurveys());
      dispatch(setSearchTerm(searchTerm));
    }

    const toastId = toast.loading("Loading...Assignments");
    dispatch(setLoading(true));

    try {
      const response = await apiConnector(
        "GET", 
        `${surveyEndpoints.GET_ALL_SURVEY}/${user?._id}?questionaireType=${questionnaireType}&page=${page}&perPage=${limit}&search=${searchTerm}`, 
        null, 
        { Authorization: `Bearer ${token}` }
      );

      if (response.status !== 200) {
        throw new Error("Could not fetch Assignments data");
      }

      const result = response?.data?.data;
      const totalSurveys = response.data.totalSurveys;
      const totalPages = response.data.totalPages;

      if (reset || searchTerm !== previousSearchTerm || page === 1) {
        dispatch(setSurveys({ surveys: result, totalSurveys, totalPages, currentPage: page, searchTerm }));
      } else {
        dispatch(appendSurveys({ surveys: result, totalSurveys, totalPages, currentPage: page, searchTerm }));
      }

      toast.success("Assignments fetched successfully");
    } catch (error) {
      toast.error(`Error! ${error?.response?.data?.message}`)
    } finally {
      dispatch(setLoading(false));
      toast.dismiss(toastId);
    }
  };
}

export function fetchAssignedSurveys(questionnaireType, token, page, limit, searchTerm, reset = false) {
  return async (dispatch, getState) => {
    const user = getState().profile?.user;
    const { searchTerm: previousSearchTerm } = getState().surveys;

    if (reset || searchTerm !== previousSearchTerm) {
      dispatch(clearSurveys());
      dispatch(setSearchTerm(searchTerm));
    }

    const toastId = toast.loading("Loading...Assignments");
    dispatch(setLoading(true));

    try {
      const response = await apiConnector(
        "GET", 
        `${surveyEndpoints.GET_ALL_ASSIGNED_SURVEY}?questionaireType=${questionnaireType}&page=${page}&limit=${limit}&searchTerm=${searchTerm}`, 
        null, 
        { Authorization: `Bearer ${token}` }
      );

      if (response.status !== 200) {
        throw new Error("Could not fetch Assignments data");
      }
      console.log(response)
      const result = response?.data?.data?.surveys;
      const totalSurveys = response.data.totalSurveys;
      const totalPages = response.data.totalPages;

      if (reset || searchTerm !== previousSearchTerm || page === 1) {
        dispatch(setSurveys({ surveys: result, totalSurveys, totalPages, currentPage: page, searchTerm }));
      } else {
        dispatch(appendSurveys({ surveys: result, totalSurveys, totalPages, currentPage: page, searchTerm }));
      }

      toast.success("Assignments fetched successfully");
    } catch (error) {
      toast.error(`Error! ${error?.response?.data?.message}`)
    } finally {
      dispatch(setLoading(false));
      toast.dismiss(toastId);
    }
  };
}
