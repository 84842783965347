import { Routes, Route, Navigate } from "react-router-dom";
import AddSchoolPage from "../pages/school/AddSchoolPage";
import SchoolProfilePage from "../pages/school/SchoolProfilePage";
import AllSchoolPage from "../pages/school/AllSchoolPage";
import AddStudentPage from "../pages/student/AddStudentPage";
import StudentProfilePage from "../pages/student/StudentProfilePage";
import AllStudentPage from "../pages/student/AllStudentPage";
import AllTeacherPage from "../pages/teacher/AllTeacherPage";
import AllSurveyPage from "../pages/survey/AllSurvey";
import AllAssignedSurvey from '../pages/survey/AssignedToCounsellor/AllAssignedSurvey.jsx';
import AddSurveyPage from "../pages/survey/AddSurvey";
import MainLayout from "../Layout/MainLayout";
import AuthLayout from "../Layout/AuthLayout";
import Dashboard from '../pages/Dashboard/Dashboard';
import AllCourse from '../pages/course/AllCourse';
import AllNotifications from '../pages/Notification/AllNotification';
import Auth from "../pages/auth/Auth";
import TeacherProfile from "../pages/teacher/TeacherProfile";
import Profile from '../pages/profile/Profile';
import SurveyDetailPage from "../pages/survey/SurveyDetailPage";
import NotificationSpecific from '../pages/Notification/NotificationSpecific';
import CreateNotification from '../pages/Notification/CreateNotification';
import AllReports from "../pages/Report/AllReports";
import AllAssignedReport from '../pages/Report/AllAssignedReports.jsx';
import ViewCourse from "../pages/course/ViewCourse";
import AddCourse from "../pages/course/AddCourse";
import AddTeacherPage from '../pages/teacher/AddTeacherPage';
import AddReport from '../pages/Report/AddReport';
import ReportSpecific from '../pages/Report/ReportSpecific';
import SurveyResponse from "../pages/survey/SurveyResponse";
import CourseStudent from '../pages/course/CourseStudent.jsx';
import CourseTeacher from "../pages/course/CourseTeacher.jsx";
import AllCertification from '../pages/certification/AllCertification.jsx';
import CreateCertification from '../pages/certification/CreateCertification.jsx';
import ViewCertification from '../pages/certification/ViewCertification.jsx';
import AssignedStudentPage from "../components/teacher/AssignedStudentPage.jsx";
import AllBrands from '../pages/brand/AllBrands.jsx';
import AddBrands from '../pages/brand/AddBrands.jsx';
import BrandProfile from '../pages/brand/BrandProfilePage.jsx';
import AllCounsellors from '../pages/counselor/AllCounselors.jsx';
import AddCounsellor from '../pages/counselor/AddCounselor.jsx';
import CouncellorDetailPage from '../pages/counselor/CounselorDetailPage.jsx';
import NotAuthorized from "../pages/extra/NotAuthorized.jsx";
import SurveyClients from "../pages/survey/SurveyClients.jsx";
import AttemptSurvey from '../pages/survey/AssignedToCounsellor/AttemptSurvey.jsx';


import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import QuizLayout from "../Layout/QuizLayout.jsx";

export default function Router() {
  const profileState = useSelector((state) => state.profile)
  const [accessFunctionalities, setAccessFunctionalities] = useState([]);
  const user = profileState.user;

  useEffect(() => {
    if (user) {
      setAccessFunctionalities(user?.permission || [])
    }
  }, [user]);

  return (
    <Routes>
      {/* Public routes */}
      <Route element={<AuthLayout />}>
        <Route path="/login" element={<Auth />} />
        <Route path="/signup" element={<Auth />} />
      </Route>

      <Route element={<QuizLayout />}>
        <Route path="/attempt-assessment/:surveyId" element={<AttemptSurvey />} />
      </Route>

      {/* Protected routes */}
      <Route element={<MainLayout />}>
        <Route path="/" element={<Dashboard />} />
        <Route path="/profile" element={<Profile />} />

        {/* Survey Assigned to the Counsellor */}
        <Route path="/assigned-survey" element={<AllAssignedSurvey />} />

         {/* Certification */}
        <Route path="/certifications" element={<AllCertification />} />
        <Route path="/assigned-reports" element={<AllAssignedReport />} />


        {/* Courses Assigned to the Counsellor */}
        <Route path="/courses" element={<AllCourse />} />
        <Route path="/course/:id" element={<ViewCourse />} />

        {/* Student Management Routes */}
        {accessFunctionalities.includes("student-management") ? (
          <>
            <Route path="/addstudent" element={<AddStudentPage />} />
            <Route path="/studentprofile/:id" element={<StudentProfilePage />} />
            <Route path="/students" element={<AllStudentPage />} />
          </>
        ) : (
          <Route path="*" element={<Navigate to="/not-authorized" />} />
        )}

        {/* Survey Management Routes */}
        {accessFunctionalities.includes("assessment-management") ? (
          <>
            <Route path="/surveys" element={<AllSurveyPage />} />
            <Route path="/survey/:id" element={<SurveyDetailPage />} />
            <Route path="/survey-clients/:surveyId" element={<SurveyClients />} />
            <Route path="/add-survey" element={<AddSurveyPage />} />
            <Route path="/survey-response/:responseId" element={<SurveyResponse />} />
          </>
        ) : null}

        {/* Report Management Routes */}
        {accessFunctionalities.includes("report-management") ? (
          <>
            <Route path="/reports" element={<AllReports />} />
            <Route path="/report/:reportId" element={<ReportSpecific />} />
            <Route path="/add-report" element={<AddReport />} />
          </>
        ) : null}

        {/* Certification Management Routes */}
        {/* {accessFunctionalities.includes("certificate-management") ? (
          <>
            <Route path="/certifications" element={<AllCertification />} />
            <Route path="/certification/:id" element={<ViewCertification />} />
            <Route path="/add-certificate" element={<CreateCertification />} />
          </>
        ) : null} */}

        {/* Notification Routes */}
        <Route path="/notifications" element={<AllNotifications />} />
        <Route path="/notification/:notificationId" element={<NotificationSpecific />} />
        <Route path="/create-notification" element={<CreateNotification />} />

        <Route path="/not-authorized" element={<NotAuthorized />} />
        {/* School Management Routes
        {accessFunctionalities.includes("school-management") ? (
          <>
            <Route path="/addschool" element={<AddSchoolPage />} />
            <Route path="/schoolprofile/:id" element={<SchoolProfilePage />} />
            <Route path="/schools" element={<AllSchoolPage />} />
          </>
        ) : null} */}

        {/* Teacher Management Routes
        {accessFunctionalities.includes("teacher-management") ? (
          <>
            <Route path="/teachers" element={<AllTeacherPage />} />
            <Route path="/teacherprofile/:id" element={<TeacherProfile />} />
            <Route path="/add-teacher" element={<AddTeacherPage />} />
            <Route path="/teacher/:id/assigned-students" element={<AssignedStudentPage />} />
          </>
        ) : null} */}

        {/* Course Management Routes
        {accessFunctionalities.includes("course-management") ? (
          <>
            <Route path="/courses" element={<AllCourse />} />
            <Route path="/course-students/:courseId" element={<CourseStudent />} />
            <Route path="/course-teachers/:courseId" element={<CourseTeacher />} />
            <Route path="/course/:id" element={<ViewCourse />} />
            <Route path="/add-course" element={<AddCourse />} />
          </>
        ) : null} */}



        {/* Brand Management Routes */}
        {/* {accessFunctionalities.includes("brand-management") ? (
          <>
            <Route path="/brands" element={<AllBrands />} />
            <Route path="/brandprofile/:id" element={<BrandProfile />} />
            <Route path="/addbrand" element={<AddBrands />} />
          </>
        ) : null} */}
      </Route>

      {/* Catch-all route */}
      <Route path="*" element={<Navigate to="/not-authorized" />} />
    </Routes>
  );
}

