import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { toast } from "react-hot-toast";
import { Tabs, Tab } from '@mui/material';
import {
  Container,
  Box,
  Typography,
  Grid,
  Avatar,
  TextField,
  InputAdornment,
  Button,
  InputLabel,
} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import { apiConnector } from '../../services/ApiConnector';
import { studentEndpoints } from '../../services/ApiEndPoints'; // Adjust import if needed
import { useSelector } from 'react-redux';
import {useNavigate} from 'react-router-dom'

const TabPanel = ({ children, value, index }) => {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};



const StudentProfile = () => {
  const token = useSelector((state) => state.auth.token);
  const navigate = useNavigate();
  const { id } = useParams();
  const [studentData, setStudentData] = useState(null);
  const [updateData, setUpdateData] = useState({});
  const [editing, setEditing] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    const fetchStudentProfile = async () => {
      const toastId = toast.loading("Loading client profile...");

      try {
        const response = await apiConnector(
          "GET",
          `${studentEndpoints.GET_STUDENT_DETAIL}/${id}`,
          null,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }
        );

        setStudentData(response?.data?.data);
        toast.success("Client profile loaded successfully");
      } catch (error) {
        toast.error(`Failed to load Client profile: ${error.message}`);
        console.error("FETCH Client PROFILE ERROR:", error);
      } finally {
        toast.dismiss(toastId);
      }
    };

    fetchStudentProfile();
  }, [id, token]);

  const handleEdit = () => {
    setEditing(true);
  };


  const handleUpdate = async () => {
    // Perform validation before making the API call
    if (updateData?.name?.trim() === "") {
      toast.error("Client name is required.");
      return;
    }
    const phonePattern = /^[0-9]{10}$/;
    if (updateData?.phone && !phonePattern.test(updateData.phone)) {
      return toast.error("Phone number must be 10 digits.");
    }

    if (updateData?.parentContact && !phonePattern.test(updateData.parentContact)) {
      return toast.error("Parent Phone number must be 10 digits.");
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (updateData?.email && !emailPattern.test(updateData.email)) {
      return toast.error("Invalid email address.");
    }

    const pincodePattern = /^[0-9]{6}$/;
    if (updateData?.pincode && !pincodePattern.test(updateData.pincode)) {
      return toast.error("Pincode must be 6 digits.");
    }

    // Proceed with the API call if validations pass
    setEditing(false);
    const toastId = toast.loading("Updating Client Data...");

    try {
      const response = await apiConnector(
        "PUT",
        `${studentEndpoints.UPDATE_STUDENT_DETAIL}/${id}`,
        updateData,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );

      setStudentData(response?.data?.data);
      toast.success("Client profile updated successfully");
    } catch (error) {
      toast.error(`Failed to update client profile: ${error.message}`);
      console.error("UPDATE client PROFILE ERROR:", error);
    } finally {
      toast.dismiss(toastId);
    }
  };

  const handleChange = (e) => {
    setStudentData({
      ...studentData,
      [e.target.name]: e.target.value,
    });
    setUpdateData({
      ...updateData,
      [e.target.name]: e.target.value
    });
  };

  if (!studentData) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Container maxWidth="md" style={{ paddingTop: "20px", paddingBottom: '50px' }}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        borderRadius={8}
        p={3}
        bgcolor="white"
        position="relative"
      >
        <Avatar
          alt="Profile Photo"
          src={studentData?.profilePhoto}
          style={{ width: 100, height: 100, marginBottom: "20px" }}
        />
        {
          editing ? (
            <>
              <InputLabel>Client Name</InputLabel>
              <TextField
                name="name"
                value={updateData.name || studentData.name}
                onChange={handleChange}
                variant="outlined"
                sx={{ width: '50%', mx: 'auto' }}
              />
            </>
          ) : (
            <Typography variant="h4" gutterBottom>
              {studentData.name}
            </Typography>
          )
        }

        {
          editing ? (
            <Button
              onClick={handleUpdate}
              variant="outlined"
              sx={{
                position: "absolute",
                top: 50,
                right: 10,
                color: "#FF6636",
                borderColor: "#FF6636",
                borderRadius: "4px",
                "&:hover": {
                  borderColor: "#FF6636",
                  backgroundColor: "#FF6636",
                  color: "#fff",
                },
              }}
            >
              Update Details
            </Button>
          ) : (
            <Button
              onClick={handleEdit}
              variant="outlined"
              sx={{
                position: "absolute",
                top: 50,
                right: 10,
                color: "#FF6636",
                borderColor: "#FF6636",
                borderRadius: "4px",
                "&:hover": {
                  borderColor: "#FF6636",
                  backgroundColor: "#FF6636",
                  color: "#fff",
                },
              }}
            >
              Edit Details
            </Button>
          )
        }

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" style={{ marginBottom: "5px" }}>
              <strong>Phone:</strong>
            </Typography>
            <TextField
              name="phone"
              value={updateData.phone || studentData.phone}
              onChange={handleChange}
              disabled={!editing}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIcon color="action" />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>

          {/* <Grid item xs={12} sm={6}>
            <Typography variant="body1" style={{ marginBottom: "5px" }}>
              <strong>Brand Id:</strong>
            </Typography>
            <TextField
              name="BrandId"
              value={updateData?.brandId?._id || studentData?.brandId?._id}
              onChange={handleChange}
              disabled={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">

                  </InputAdornment>
                ),
              }}
              variant="outlined"
              fullWidth
            />
          </Grid> */}

          <Grid item xs={12} sm={6}>
            <Typography variant="body1" style={{ marginBottom: "5px" }}>
              <strong>MID:</strong>
            </Typography>
            <TextField
              name="mid"
              value={updateData?.mid || studentData?.mid}
              onChange={handleChange}
              disabled={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">

                  </InputAdornment>
                ),
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>

          {/* <Grid item xs={12} sm={6}>
            <Typography variant="body1" style={{ marginBottom: "5px" }}>
              <strong>Brand Name:</strong>
            </Typography>
            <TextField
              name="BrandId"
              value={updateData?.brandId?.title || studentData?.brandId?.title}
              onChange={handleChange}
              disabled={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">

                  </InputAdornment>
                ),
              }}
              variant="outlined"
              fullWidth
            />
          </Grid> */}

          <Grid item xs={12} sm={6}>
            <Typography variant="body1" style={{ marginBottom: "5px" }}>
              <strong>UserId:</strong>
            </Typography>
            <TextField
              name="userId"
              value={updateData?.userId || studentData.userId}
              onChange={handleChange}
              disabled={true}
              variant="outlined"
              fullWidth
            />
          </Grid>

          {/* <Grid item xs={12} sm={6}>
            <Typography variant="body1" style={{ marginBottom: "5px" }}>
              <strong>School:</strong>
            </Typography>
            <TextField
              name="schoolName"
              value={studentData?.schoolId?.name}
              onChange={handleChange}
              disabled={true}
              variant="outlined"
              fullWidth
            />
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" style={{ marginBottom: "5px" }}>
              <strong>State:</strong>
            </Typography>
            <TextField
              name="state"
              value={updateData.state || studentData.state}
              onChange={handleChange}
              disabled={!editing}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" style={{ marginBottom: "5px" }}>
              <strong>City:</strong>
            </Typography>
            <TextField
              name="city"
              value={updateData.city || studentData.city}
              onChange={handleChange}
              disabled={!editing}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" style={{ marginBottom: "5px" }}>
              <strong>Landmark:</strong>
            </Typography>
            <TextField
              name="landmark"
              value={updateData.landmark || studentData.landmark}
              onChange={handleChange}
              disabled={!editing}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" style={{ marginBottom: "5px" }}>
              <strong>Pincode:</strong>
            </Typography>
            <TextField
              name="pincode"
              value={updateData.pincode || studentData.pincode}
              onChange={handleChange}
              disabled={!editing}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" style={{ marginBottom: "5px" }}>
              <strong>Email:</strong>
            </Typography>
            <TextField
              name="email"
              value={updateData.email || studentData.email}
              onChange={handleChange}
              disabled={!editing}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" style={{ marginBottom: "5px" }}>
              <strong>Parent Name:</strong>
            </Typography>
            <TextField
              name="parentName"
              value={updateData.parentName || studentData.parentName}
              onChange={handleChange}
              disabled={!editing}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" style={{ marginBottom: "5px" }}>
              <strong>Parent Contact:</strong>
            </Typography>
            <TextField
              name="parentContact"
              value={updateData.parentContact || studentData.parentContact}
              onChange={handleChange}
              disabled={!editing}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" style={{ marginBottom: "5px" }}>
              <strong>Class:</strong>
            </Typography>
            <TextField
              name="class"
              value={updateData.class || studentData.class}
              onChange={handleChange}
              disabled={!editing}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" style={{ marginBottom: "5px" }}>
              <strong>Address:</strong>
            </Typography>
            <TextField
              name="address"
              value={updateData.address || studentData.address}
              onChange={handleChange}
              disabled={!editing}
              variant="outlined"
              fullWidth
              multiline
              rows={2}
            />
          </Grid>
        </Grid>
      </Box>
      {/* THe report and survey  page */}
      <Box sx={{ width: '100%' }}>
        {/* Tabs */}
        <Tabs value={activeTab} onChange={handleTabChange} aria-label="Survey and Report Tabs">
          <Tab label="Assigned Survey" />
          <Tab label="Assigned Report" />
        </Tabs>

        {/* Tab Panels */}
        <TabPanel value={activeTab} index={0}>
          {studentData?.surveys?.length ? (
            <ul>
              {studentData?.surveys?.map((item, index) => (
                <li  key={index}>{item.name} <Button onClick={()=>{ 
                     window.open(`/survey/${item?._id}`)
                }}>Go</Button></li>
              ))}
            </ul>
          ) : (
            <Typography>No surveys available</Typography>
          )}
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          {studentData?.reports?.length ? (
            <ul>
              {studentData.reports.map((item, index) => (
                <li key={index}>{item?.fileName} <Button onClick={()=>{
                  window.open(item?.url,"_blank")
                }}>Go</Button></li>
              ))}
            </ul>
          ) : (
            <Typography>No reports available</Typography>
          )}
        </TabPanel>
      </Box>
    </Container>
  );
};

export default StudentProfile;
