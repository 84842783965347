import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useDebounce } from "use-debounce";
import { apiConnector } from "../../services/ApiConnector";
import { surveyEndpoints } from "../../services/ApiEndPoints";
import { useSelector } from "react-redux";


const ClientModal = ({ open, onClose, onSubmit, surveyId }) => {
  const token = useSelector((state) => state.auth.token);
  const [clients, setClients] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 800); // Debounce for 800ms
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);

  const fetchClients = async (page = 1, query = "", reset = false) => {
    setLoading(true);
    try {
      const response = await apiConnector(
        "GET",
        `${surveyEndpoints.GET_UNENROLLED_STUDENT}?surveyId=${surveyId}&page=${page}&perPage=40&search=${query}`,
        null,
        { Authorization: `Bearer ${token}` }
      );

      console.log(response)
      if (reset) {
        setClients(response?.data?.data);
      } else {
        setClients((prev) => [...prev, ...(response?.data?.data)]);
      }

      setTotalPages(response?.data.totalPages || 1);
    } catch (error) {
      console.error("Failed to fetch clients", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      fetchClients(1, debouncedSearchTerm, true);
      setCurrentPage(1);
    }
  }, [open, debouncedSearchTerm]);

  useEffect(() => {
    if (currentPage > 1 && open) {
      fetchClients(currentPage, debouncedSearchTerm);
    }
  }, [currentPage]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const handleLoadMore = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handleSelectClient = (client) => {
    setSelectedClients((prev) => {
      if (prev.includes(client._id)) {
        return prev.filter((id) => id !== client._id);
      } else {
        return [...prev, client._id];
      }
    });
  };

  const handleSubmitClick = () => {
    const selected = clients.filter((client) =>
      selectedClients.includes(client._id)
    );
    onSubmit(selected, setSelectedClients);
  };

  console.log(open)

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: 500,
          height: "80vh",
          overflowY: "auto",
          bgcolor: "background.paper",
          p: 3,
          borderRadius: 1,
        }}
      >
        <Typography variant="h6" gutterBottom>
          Select Clients
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search clients"
          value={searchTerm}
          onChange={handleSearchChange}
          sx={{ mb: 2 }}
        />
        <List>
          {clients?.map((client, index) => (
            <ListItem
              key={index + 1}
              button
              onClick={() => handleSelectClient(client)}
            >
              <Checkbox checked={selectedClients.includes(client?._id)} />
              <ListItemText primary={client?.name} />
              <ListItemText
                sx={{ textAlign: "end" }}
                primary={client?.userId}
              />
            </ListItem>
          ))}
        </List>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 2,
          }}
        >
          <Button variant="outlined" onClick={handleSubmitClick}>
            Assign
          </Button>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          {currentPage < totalPages && (
            loading ? (
              <Button variant="text" disabled>
                Loading...
              </Button>
            ) : (
              <Button variant="text" onClick={handleLoadMore}>
                Load More
              </Button>
            )
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default ClientModal;
