import { toast } from "react-hot-toast";
import { apiConnector } from '../ApiConnector';
import { reportEndpoints } from '../ApiEndPoints'; // Make sure to import the correct endpoints
import { setReports, appendReports, setLoading, clearReports, setSearchTerm } from '../../Store/Slices/reportSlice'; // Adjust import path as necessary

export function fetchReports(token, page, limit, searchTerm, reset = false) {
  return async (dispatch, getState) => {
    const { searchTerm: previousSearchTerm } = getState().reports;

    if (reset || searchTerm !== previousSearchTerm) {
      dispatch(clearReports());
      dispatch(setSearchTerm(searchTerm));
    }

    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));

    try {
      const response = await apiConnector(
        "GET",
        `${reportEndpoints.ALL_REPORT_DATA_API}?page=${page}&limit=${limit}&searchTerm=${searchTerm}`,
        null,
        { Authorization: `Bearer ${token}` }
      );
      
      if (response.status !== 200) {
        throw new Error("Could not fetch report data");
      }

      const result = response?.data?.data;
      const totalReports = response.data.totalReports;
      const totalPages = response.data.totalPages;
      
      if (reset || searchTerm !== previousSearchTerm || page === 1) {
        dispatch(setReports({ reports: result, totalReports, totalPages, currentPage: page, searchTerm }));
      } else {
        dispatch(appendReports({ reports: result, totalReports, totalPages, currentPage: page, searchTerm }));
      }

      toast.success("Reports fetched successfully");
    } catch (error) {
      toast.error(`Error! ${error?.response?.data?.message}`)
    } finally {
      dispatch(setLoading(false));
      toast.dismiss(toastId);
    }
  };
}


export function fetchOwnReports(token, page, limit, searchTerm, reset = false) {
  return async (dispatch, getState) => {
    const { searchTerm: previousSearchTerm } = getState().reports;

    if (reset || searchTerm !== previousSearchTerm) {
      dispatch(clearReports());
      dispatch(setSearchTerm(searchTerm));
    }

    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));

    try {
      const response = await apiConnector(
        "GET",
        `${reportEndpoints.ALL_OWN_REPORT_DATA_API}?page=${page}&limit=${limit}&searchTerm=${searchTerm}`,
        null,
        { Authorization: `Bearer ${token}` }
      );
      
      if (response.status !== 200) {
        throw new Error("Could not fetch report data");
      }

      const result = response?.data?.data;
      const totalReports = response.data.totalReports;
      const totalPages = response.data.totalPages;
      
      if (reset || searchTerm !== previousSearchTerm || page === 1) {
        dispatch(setReports({ reports: result, totalReports, totalPages, currentPage: page, searchTerm }));
      } else {
        dispatch(appendReports({ reports: result, totalReports, totalPages, currentPage: page, searchTerm }));
      }

      toast.success("Reports fetched successfully");
    } catch (error) {
      toast.error(`Error! ${error?.response?.data?.message}`)
    } finally {
      dispatch(setLoading(false));
      toast.dismiss(toastId);
    }
  };
}
