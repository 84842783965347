import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TextField,
  InputAdornment,
  Container,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "use-debounce";

import { clearSurveys, setSearchTerm, setSurveys, updateCurrentPage } from "../../../Store/Slices/surveySlice";
import { fetchAssignedSurveys } from "../../../services/Operations/SurveyOperation";

const ClientAssignedSurvey = () => {
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { surveys, totalSurveys, currentPage, loading, totalPages, searchTerm } = useSelector((state) => state.surveys);
  const [surveysPerPage, setSurveyPage] = useState(10);
  const [debouncedSearchTerm] = useDebounce(searchTerm, 1000);

  const handleChange = (event) => {
    setSurveyPage(event.target.value);
  };

  useEffect(() => {
    const fetchSurveyData = async () => {
      dispatch(fetchAssignedSurveys("quiz", token, 1, surveysPerPage, debouncedSearchTerm, true));
    };

    if (!loading) {
      fetchSurveyData();
    }
  }, [debouncedSearchTerm, dispatch, surveysPerPage]);

  useEffect(() => {
    const fetchSurveyData = async () => {
      dispatch(fetchAssignedSurveys("quiz", token, currentPage, surveysPerPage, debouncedSearchTerm));
    };

    if (!loading && currentPage > 1) {
      fetchSurveyData();
    }
  }, [currentPage, debouncedSearchTerm, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearSurveys());
    };
  }, [dispatch]);

  const handleLoadMore = () => {
    if (currentPage < totalPages) {
      dispatch(updateCurrentPage(currentPage + 1));
    }
  };

  const handleSearchChange = (e) => {
    dispatch(setSearchTerm(e.target.value));
    dispatch(updateCurrentPage(1));
  };

  return (
    <Container sx={{ mt: 4 }}>
      <Box>
        {/* Header Section */}
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h5" fontWeight="bold">
            Assigned Assessments <Typography component="span">({totalSurveys} Assessments Available)</Typography>
          </Typography>
          <TextField
            variant="outlined"
            placeholder="Search Assessments"
            value={searchTerm}
            onChange={handleSearchChange}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        {/* Table Section */}
        <TableContainer component={Paper} sx={{ borderRadius: "8px", border: "1px solid #f5a48b", marginTop: '10px' }}>
          <Table sx={{ tableLayout: 'auto' }}>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#FFBE9D" }}>
                <TableCell sx={{ fontWeight: 'bold' }}>Sr. No</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Assessments Name</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Type</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>End-Date</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ fontSize: "16px", fontWeight: "bold" }}>
              {surveys?.map((survey, index) => (
                <TableRow key={survey.id}>
                  <TableCell>{index + 1}.</TableCell>
                  <TableCell>{survey?.name}</TableCell>
                  <TableCell>{survey?.type}</TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>{survey?.endDate.split('T')[0]}</TableCell>
                  <TableCell>
                  {/* Attempt */}
                   {
                     survey?.action=='attempt' &&  <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => window.open(`/attempt-assessment/${survey._id}`,"_blank")}
                    >
                      Attempt
                    </Button>
                   }
                   {/* ReAttempt  */}
                   {
                     survey?.action=='reattempt' &&  <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => window.open(`/attempt-assessment/${survey._id}`,"_blank")}
                    >
                      Re-Attempt
                    </Button>
                   }
                   {/* View Response */}
                   {
                     survey?.action=='view' &&  <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => window.open(`/survey-response/${survey?.surveyResponseId}`,"_blank")}
                    >
                      View Response
                    </Button>
                   }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box mt={3} display={'flex'} justifyContent={'end'} alignItems={'center'} gap={'10px'} textAlign="center">
        <Typography>Page -{currentPage}/{totalPages}</Typography>
        <Button
          onClick={handleLoadMore}
          disabled={currentPage >= totalPages}
          variant="outlined"
          color="primary"
        >
          Load More
        </Button>
        {/* page size */}
        <FormControl>
          <Select
            value={surveysPerPage}
            onChange={handleChange}
            displayEmpty
            size="small"
            renderValue={(value) => `Page Size - ${value}`}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Container>
  );
};

export default ClientAssignedSurvey;
