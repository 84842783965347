import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  Card,
  CardContent,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "use-debounce";
import { fetchNotifications } from '../../services/Operations/NotificationOperation';
import { clearNotifications, setSearchTerm, updateCurrentPage } from '../../Store/Slices/notificationSlice';
import { format } from "date-fns";
import NotificationCard from "../../components/Notification/NotificationCard"
const Notification = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const { notifications, totalNotifications, currentPage, loading, totalPages, searchTerm } = useSelector((state) => state.notifications);
  const [notificationsPerPage, setNotificationsPerPage] = useState(10);  // Page size
  const [debouncedSearchTerm] = useDebounce(searchTerm, 1000);

  // Handle notifications per page change
  const handleChange = (event) => {
    setNotificationsPerPage(event.target.value);
  };

  // Fetch notifications based on search term or page size
  useEffect(() => {
    const fetchNotificationData = async () => {
      dispatch(fetchNotifications(token, 1, notificationsPerPage, debouncedSearchTerm, true));
    };
    if (!loading) {
      fetchNotificationData();
    }
  }, [debouncedSearchTerm, notificationsPerPage, dispatch]);

  // Handle pagination
  useEffect(() => {
    const fetchNotificationData = async () => {
      dispatch(fetchNotifications(token, currentPage, notificationsPerPage, debouncedSearchTerm));
    };
    if (!loading && currentPage > 1) {
      fetchNotificationData();
    }
  }, [currentPage, debouncedSearchTerm, dispatch]);

  // Clean up on component unmount
  useEffect(() => {
    return () => {
      dispatch(clearNotifications());
    };
  }, [dispatch]);

  // Handle load more functionality
  const handleLoadMore = () => {
    if (currentPage < totalPages) {
      dispatch(updateCurrentPage(currentPage + 1));
    }
  };

  // Handle search term change
  const handleSearchChange = (e) => {
    dispatch(setSearchTerm(e.target.value));
    dispatch(updateCurrentPage(1));  // Reset to first page on new search
  };


  console.log(notifications)
  return (
    <div style={{ padding: "20px" }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={5}>
        <Typography variant="h5" fontWeight="bold">
          All Notifications <Typography component="span">({totalNotifications} Notifications Available)</Typography>
        </Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <TextField
            variant="outlined"
            placeholder="Search Notifications"
            onChange={handleSearchChange}
            value={searchTerm}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="error"
            startIcon={<AddIcon />}
            sx={{ backgroundColor: "#EF492E" }}
            onClick={() => { navigate("/create-notification"); }}
          >
            Create Notification
          </Button>
        </Box>
      </Box>

      {/* Empty map for notifications where you will insert your card component */}
      {/* <Box>
        {notifications?.map((notification, index) => (
          <Card key={index} backgroundColor={notification?.isView==true?"white":"gray"} sx={{ width:'100%', mb: 2,":hover":{backgroundColor:'#F5F5F5'},cursor:'pointer'  }} onClick={()=>{navigate(`/notification/${notification._id}`)}}>
      <CardContent>
        <Typography variant="h6" component="div" color={'#EF492E'}>
          {notification?.name}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 1.5 }}>
          {notification?.type}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 1.5 }}>
          {format(new Date(notification?.createdAt), "MMMM d, yyyy h:mm a")}
        </Typography>
        <Typography variant="body1" component="div">
          {notification?.description.slice(0,100)}...
        </Typography>
      </CardContent>
    </Card>
        ))}
      </Box> */}
     <NotificationCard notifications={notifications}/>
      <Box mt={3} display={"flex"} justifyContent={"end"} alignItems={"center"} gap={"10px"} textAlign="center">
        <Typography>Page - {currentPage} / {totalPages}</Typography>
        <Button
          onClick={handleLoadMore}
          disabled={currentPage >= totalPages}
          variant="outlined"
          color="primary"
        >
          Load More
        </Button>

        <FormControl>
          <Select
            value={notificationsPerPage}
            onChange={handleChange}
            displayEmpty
            size="small"
            renderValue={(value) => `Page Size - ${value}`}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </div>
  );
};

export default Notification;
