import React from 'react';
import { Modal, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ImageReaderModal = ({ selectedImage, setImageModalOpen }) => {
  const handleContextMenu = (e) => {
    e.preventDefault(); // Disable right-click menu
  };

  const handleDragStart = (e) => {
    e.preventDefault(); // Prevent dragging
  };

  const handleClose = () => {
    setImageModalOpen(false); // Close the modal
  };

  return (
    <Modal
      open={!!selectedImage} // Modal open state
      onClose={handleClose} // Close when backdrop is clicked
      aria-labelledby="image-modal"
      aria-describedby="image-modal-description"
      closeAfterTransition
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} // Center modal
    >
      <Box
        sx={{
          position: 'relative',
          bgcolor: 'white',
          p: 2,
          width: '80%',
          height: '80%',
          borderRadius: '8px',
          boxShadow: 24,
          outline: 'none',
        }}
        onClick={(e) => e.stopPropagation()} // Prevent modal close when content is clicked
        onContextMenu={handleContextMenu} // Disable right-click
        onDragStart={handleDragStart} // Prevent dragging
      >
        {/* Close button */}
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            color: 'gray',
          }}
        >
          <CloseIcon />
        </IconButton>

        {/* Display selected image */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <img
            src={selectedImage}
            alt="Selected"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain',
            }}
            onContextMenu={handleContextMenu} // Disable right-click on image
            onDragStart={handleDragStart} // Prevent dragging of image
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default ImageReaderModal;
