import React, { useState } from "react";
import { toast } from "react-hot-toast"
import {
  Container,
  Typography,
  Stack,
  Grid,
  TextField,
  Button,
  Box,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { styled } from "@mui/material/styles";
import { stateOptions } from "../../data/data";
import {useNavigate} from 'react-router-dom'
import { studentEndpoints } from "../../services/ApiEndPoints";
import { apiConnector } from "../../services/ApiConnector";
import { useSelector } from "react-redux";


// import useFirebaseImageUpload from "../../hooks/useFirebaseImageUpload";
import useAwsFileUpload from "../../hooks/useAwsImageUpload";


const AddStudent = () => {
  const [mid,setMid] = useState(null)
  const {uploadFileToAws,uploading} = useAwsFileUpload();
  // const { uploadImageToFirebase, uploading } = useFirebaseImageUpload();  // Destructure hook
  const token = useSelector((state) => state.auth.token);
  const [loading,setLoading] = useState(false);
  const navigate = useNavigate();
  // Form Data 
  const [studentName, setStudentName] = useState("");
  const [email,setEmail] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [studentPhoto, setStudentPhoto] = useState(null);
  const [className, setClassName] = useState("");
  const [homeAddress, setHomeAddress] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [landmark, setLandmark] = useState("");
  const [pincode, setPincode] = useState("");
  const [parentName, setParentName] = useState("");
  const [parentContactNo, setParentContactNo] = useState("");
  

  const handlePhotoChange = (e) => {
    setStudentPhoto(e.target.files[0]);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const toastId = toast.loading("Loading...");
    setLoading(true);
  
    // Regex patterns for validation
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    const phoneRegex = /^[6-9]\d{9}$/; // Valid 10-digit Indian contact numbers starting with 6-9
    const pincodeRegex = /^[1-9][0-9]{5}$/; // Valid 6-digit Indian pincodes
    const nameRegex = /^[a-zA-Z\s]{2,50}$/; // Validating student name (letters and spaces, min 2 chars)
  
    // Basic validation checks
    if (!studentName ) {
      toast.error("All fields are required!");
      toast.dismiss(toastId);
      setLoading(false);
      return;
    }
  
    // Validate student's name format
    if (!nameRegex.test(studentName)) {
      toast.error("Invalid Client name format. Only letters and spaces are allowed.");
      toast.dismiss(toastId);
      setLoading(false);
      return;
    }
  
    // Validate email format
    if (email && !emailRegex.test(email)) {
      toast.error("Invalid email format.");
      toast.dismiss(toastId);
      setLoading(false);
      return;
    }
  
    // Validate contact number format
    if (contactNo && !phoneRegex.test(contactNo)) {
      toast.error("Invalid contact number. Must be a valid 10-digit number.");
      toast.dismiss(toastId);
      setLoading(false);
      return;
    }
  
    // Validate parent contact number format
    if (parentContactNo && !phoneRegex.test(parentContactNo)) {
      toast.error("Invalid parent contact number. Must be a valid 10-digit number.");
      toast.dismiss(toastId);
      setLoading(false);
      return;
    }
  
    // Validate pincode format
    if (pincode && !pincodeRegex.test(pincode)) {
      toast.error("Invalid pincode. Must be a 6-digit number.");
      toast.dismiss(toastId);
      setLoading(false);
      return;
    }
  
    try {
      let imageUrl = "";
  
      if (studentPhoto) {
        // Use the hook to upload the image
        imageUrl = await uploadFileToAws(studentPhoto);
        if (!imageUrl) {
          throw new Error("Failed to upload image");
        }
      }
  
      // Prepare student data
      const studentData = {
        name:studentName,
        email,
        contactNo,
        className,
        homeAddress,
        mid,
        state,
        city,
        landmark,
        pincode,
        parentName,
        parentContactNo,
        studentPhoto: imageUrl,
      };
  
      // API call to create student
      const response = await apiConnector(
        "POST",
        `${studentEndpoints.CREATE_STUDENT}`,
        studentData,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );
  
      // Dismiss the loading toast
      toast.dismiss(toastId);
  
      // Show success toast
      toast.success("Client Created Successfully!", {
        id: toastId, // You can reuse the same ID if needed, or remove this line
      });
  
      console.log("Client Created:", response.data);
      navigate('/students');
    } catch (error) {
      // Dismiss the loading toast
      toast.dismiss(toastId);
      setLoading(false);
  
      // Show error toast
      toast.error(`Failed to create Client. ${error?.response?.data?.message}`, {
        id: toastId, // You can reuse the same ID if needed, or remove this line
      });
  
      console.error("Error creating Client:", error);
    }
  };
  
  return (
    <Container maxWidth="md" sx={{my:'20px'}}>
      <Typography
        variant="h4"
        align="center"
        sx={{ marginTop: "1rem", color: "#FF6636" }}
      >
        Add New Client
      </Typography>
      <form onSubmit={handleSubmit}>
        <Stack display={'flex'} flexDirection={'column'} gap={'10px'}>
          <Typography variant="h6">Client Details</Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                required={true}
                type="text"
                label="Client Name"
                variant="outlined"
                fullWidth
                value={studentName}
                onChange={(e) => setStudentName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="email"
                label="email"
                variant="outlined"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="mid"
                label="MID"
                variant="outlined"
                fullWidth
                value={mid}
                onChange={(e) => setMid(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px 12px",
                  borderRadius: "4px",
                  border: "1px solid #cccccc",
                }}
              >
                <Button
                  component="label"
                  sx={{
                    color: "#000000",
                    border: "none",
                    textTransform: "none",
                    backgroundColor: "#f5f5f5",
                    padding: "4px 8px",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "0.875rem",
                  }}
                  startIcon={<AttachFileIcon />}
                >
                  Choose Image
                  <input
                    type="file"
                    hidden
                    accept=".jpg, .png"
                    onChange={handlePhotoChange}
                  />
                </Button>
                <Typography
                  variant="caption"
                  sx={{ color: "#888888", marginLeft: "8px" }}
                >
                   {
                        uploading?"Uploading":<>{studentPhoto?studentPhoto.name:"Supported Files: .jpg, .png"} </>
                      }  
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                
                type="text"
                label="Contact No."
                variant="outlined"
                fullWidth
                value={contactNo}
                onChange={(e) => setContactNo(e.target.value)}
              />
            </Grid>
           
            <Grid item xs={12} sm={6}>
              <TextField
                type="text"
                label="Class"
                variant="outlined"
                fullWidth
                value={className}
                onChange={(e) => setClassName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="text"
                label="Home Address"
                variant="outlined"
                fullWidth
                value={homeAddress}
                onChange={(e) => setHomeAddress(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="state-label">State</InputLabel>
                <Select
                  type="text"
                  labelId="state-label"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  label="State"
                >
                  {stateOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth variant="outlined">
                <TextField
                type="text"
                label="city"
                variant="outlined"
                fullWidth
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                
                type="text"
                label="Landmark"
                variant="outlined"
                fullWidth
                value={landmark}
                onChange={(e) => setLandmark(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                
                type="text"
                label="Pincode"
                variant="outlined"
                fullWidth
                value={pincode}
                onChange={(e) => setPincode(e.target.value)}
              />
            </Grid>
          </Grid>


          <Typography variant="h6" >
            Parent Details
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                type="text"
                label="Parent Name"
                variant="outlined"
                fullWidth
                value={parentName}
                onChange={(e) => setParentName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="text"
                label="Contact No."
                variant="outlined"
                fullWidth
                value={parentContactNo}
                onChange={(e) => setParentContactNo(e.target.value)}
              />
            </Grid>
          </Grid>

          <Stack direction="row" spacing={2} mt={'10px'} justifyContent="space-between">
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#ffffff",
                color: "#FF6636",
                border: "1px solid #FF6636",
                ":hover":{
                  backgroundColor: "#ffffff",
                }
              }}
              onClick={()=>{navigate('/students')}}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={loading}
              sx={{ backgroundColor: "#FF6636", color: "#ffffff",":hover":{backgroundColor:"#FF6636"} }}
            >
              Create Client
            </Button>
          </Stack>
        </Stack>
      </form>
    </Container>
  );
};

export default AddStudent;
