import React from 'react';
import { CircularProgress, Box, Typography } from "@mui/material";

const LoadingSpinner = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh", // Full viewport height to center the spinner
      }}
    >
      <CircularProgress />
      <Typography sx={{ mt: 2 }}>Loading...</Typography>
    </Box>
  );
};

export default LoadingSpinner;
