import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import { Modal, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const PdfReaderModal = ({ selectedPdf, setPdfModalOpen }) => {
  const [numPages, setNumPages] = useState(null);

  const handleContextMenu = (e) => {
    e.preventDefault(); // Disable right-click menu
  };

  const handleDragStart = (e) => {
    e.preventDefault(); // Prevent dragging
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleClose = () => {
    setPdfModalOpen(false);
  };

  return (
    <Modal
      open={!!selectedPdf}
      onClose={handleClose}
      aria-labelledby="pdf-modal"
      aria-describedby="pdf-modal-description"
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Box
        sx={{
          position: 'relative',
          bgcolor: 'white',
          p: 0,
          width: '70%',
          height: '90%',
          borderRadius: '8px',
          boxShadow: 24,
          outline: 'none',
          overflow: 'hidden',
        }}
        onClick={(e) => e.stopPropagation()}
        onContextMenu={handleContextMenu} // Disable right-click
        onDragStart={handleDragStart} // Prevent dragging
      >
        {/* Close button */}
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            color: 'gray',
          }}
        >
          <CloseIcon />
        </IconButton>

        {/* PDF Display */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center', // Center content horizontally
            alignItems: 'flex-start', // Align items to the start to avoid cutting off
            height: '100%',
            overflowY: 'auto',
            padding: 0, // Remove padding to prevent pushing content down
          }}
        >
          <Document
            file={selectedPdf}
            onLoadSuccess={onDocumentLoadSuccess}
            // renderMode="canvas" // or "svg"
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                renderTextLayer={false} // Disable text layer if not needed
                renderAnnotationLayer={false} // Disable annotation layer if not needed
                style={{
                  display: 'block', // Ensure each page is a block element
                  margin: '0 auto', // Center the page horizontally
                  minHeight: '100vh', // Ensure the page occupies full height
                  width: '100%', // Ensure the page width is responsive
                }}
              />
            ))}
          </Document>
        </Box>
      </Box>
    </Modal>
  );
};

export default PdfReaderModal;
