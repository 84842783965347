import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Container,
  Button,
  Box,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Modal,
  Card,
  CardContent,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { toast } from 'react-hot-toast';
import { useSelector } from "react-redux";
import { apiConnector } from "../../services/ApiConnector";
import { surveyEndpoints } from "../../services/ApiEndPoints";
import ClientModal from "../../components/survey/SurveyClientInsertModal"; // Import the new ClientModal component

const SurveyClients = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const { surveyId } = useParams(); // Extract surveyId from URL
  const [clientsData, setClientsData] = useState([]);
  const [surveyName, setSurveyName] = useState("");
  const [totalClients, setTotalClients] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [isCardOpen, setIsCardOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchClients = async () => {
    setLoading(true);
    const toastId = toast.loading("Fetching Survey Clients");
    try {
      const response = await apiConnector(
        "GET",
        `${surveyEndpoints.GET_SURVEY_CLIENTS}?id=${surveyId}`,
        null,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );
      console.log(response);
      setClientsData(response?.data?.data?.students);
      setSurveyName(response?.data?.data.name);
      setTotalClients(response?.data?.data?.students?.length || 0);
      toast.success("Successfully fetched clients");
    } catch (error) {
      toast.error("Failed to fetch the clients");
      console.error("Failed to fetch clients", error);
    } finally {
      setLoading(false);
      toast.dismiss(toastId);
    }
  };

  useEffect(() => {
    if (surveyId) {
      fetchClients(); // Fetch all clients in a single API call
    }
  }, [surveyId]);

  const EnrollInSurvey = async (clientIds) => {
    console.log("hello sir")
    const toastId = toast.loading("Enrolling clients...");
    try {
      const response = await apiConnector(
        "POST",
        `${surveyEndpoints.ASSIGN_SURVEY_TO_MULTIPLE_STUDENT}?id=${surveyId}`,
        { studentIds: clientIds },
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );
      console.log(response)
      toast.success("Clients enrolled successfully! Please refresh to see results");
      fetchClients();
    } catch (error) {
      toast.error("Failed to enroll clients in survey");
      console.error(error);
    } finally {
      toast.dismiss(toastId);
    }
  };

  const handleRemoveFromSurvey = async (clientId) => {
    const toastId = toast.loading("Removing client...");
    try {
      const response = await apiConnector(
        "POST",
        `${surveyEndpoints.REMOVE_CLIENT}?id=${surveyId}`,
        { studentId:clientId},
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );
      console.log(response)
      setIsCardOpen(false);
      toast.success("Removed client successfully! Please refresh to see results");
      fetchClients();
    } catch (error) {
      toast.error("Failed to remove client from survey");
      console.error(error);
    } finally {
      toast.dismiss(toastId);
    }
  };

  const handleEnroll = (clients, setSelectedClients) => {
    setIsModalOpen(false);
    setSelectedClients([]);
    let clientIds = clients?.map((client) => client._id);
    EnrollInSurvey(clientIds);
  };

  const handleMoreClick = (client) => {
    setSelectedClient(client);
    setIsCardOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Container sx={{ mt: 4 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
        <Typography variant="h4">
          {surveyName}
          <Typography component="span" color="textSecondary" sx={{ fontSize: "0.9rem" }}>
            ({totalClients} Clients)
          </Typography>
        </Typography>
        <Button
          variant="contained"
          sx={{ backgroundColor: "#EF492E", color: "#fff", ml: 1, "&:hover": { backgroundColor: "#D44428" } }}
          startIcon={<AddIcon />}
          onClick={() => {
            console.log("Open modal")
            setIsModalOpen(true)
          }}
        >
          Add New Client
        </Button>
      </Box>

      <TableContainer component={Paper} sx={{ borderRadius: "8px", border: "1px solid #f5a48b", marginTop: "10px", overflowX: "auto" }}>
        <Table sx={{ tableLayout: "auto" }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#FFBE9D" }}>
              <TableCell sx={{ fontWeight: "bold" }} width={"50px"}>S.No</TableCell>
              <TableCell sx={{ fontWeight: "bold", cursor: "pointer" }} width={"150px"}>Name</TableCell>
              <TableCell sx={{ fontWeight: "bold" }} width={"250px"}>Email</TableCell>
              <TableCell sx={{ fontWeight: "bold" }} width={"250px"}>UserId</TableCell>
              <TableCell sx={{ fontWeight: "bold" }} width={"100px"}>City</TableCell>
              <TableCell sx={{ fontWeight: "bold" }} width={"50px"}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clientsData?.length > 0 ? (
              clientsData?.map((client, index) => (
                <TableRow key={client._id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{client?.name}</TableCell>
                  <TableCell>{client?.email}</TableCell>
                  <TableCell>{client?.userId}</TableCell>
                  <TableCell>{client?.city}</TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      size="small"
                      color="primary"
                      onClick={() => handleMoreClick(client)}
                    >
                      More
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} sx={{ textAlign: "center" }}>
                  No Clients Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal to add new clients */}
      <ClientModal
        open={isModalOpen}
        onClose={handleCancel}
        onSubmit={handleEnroll}
        surveyId={surveyId}
      />

      {/* Card to display more details about a client */}
      <Modal open={isCardOpen} onClose={() => setIsCardOpen(false)}>
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minWidth: 300,
            maxWidth: 500,
            outline: "none",
            p: 2,
          }}
        >
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Client Details
            </Typography>
            <Typography><strong>Name:</strong> {selectedClient?.name}</Typography>
            <Typography><strong>Email:</strong> {selectedClient?.email}</Typography>
            <Typography><strong>Survey:</strong> {selectedClient?.city}</Typography>
            <Box sx={{ mt: 2 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleRemoveFromSurvey(selectedClient?._id)}
                sx={{  color: "#fff" }}
              >
                Remove Client
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Modal>
    </Container>
  );
};

export default SurveyClients;
