import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, CardActions, Button, Typography, TextField, InputAdornment, Grid, FormControl, Select, MenuItem, CardActionArea, CardMedia } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from 'react-redux';
import { fetchCourses } from '../../services/Operations/CourseOperation';
import { clearCourses, setSearchTerm, updateCurrentPage } from '../../Store/Slices/courseSlice';
import { useDebounce } from 'use-debounce';

const CourseCard = ({ course, index }) => {
  const navigate = useNavigate();

  const handleView = (id) => {
    navigate(`/course/${id}`);
  };

  return (
    <Card sx={{  height:280, width: 300, margin: "10px", display: "flex", flexDirection: "column", justifyContent:'start' }}>
    <CardActionArea sx={{py:2}} onClick={() => handleView(course?._id)}>
      {/* Course Image */}
      <CardMedia
        component="img"
        height="140"
        sx={{objectFit:'contain'}}
         // Image takes 60% of the card height
        image={course.image || "https://via.placeholder.com/400"} // Fallback image
        alt={course?.name || "N/A"}
      />

      {/* Course Details */}
      <CardContent>
        <Typography fontWeight={'bold'} fontSize={'14px'} lineHeight={'20px'} component="div">
          {course?.name || "N/A"}
        </Typography>
        <Typography mt={1} variant="body2" color="text.secondary">
          Category: {course?.category?.name || "N/A"}
        </Typography>
        <Typography mt={1} variant="body2" color="text.secondary">
          Sub-Category: {course?.subCategory?.name || "N/A"}
        </Typography>
      </CardContent>
    </CardActionArea>
  </Card>
  );
};

function App() {
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const { courses, totalCourses, currentPage, loading, totalPages, searchTerm } = useSelector((state) => state.courses);
  const [coursePerPage, setCoursePerPage] = useState(10);
  const [debouncedSearchTerm] = useDebounce(searchTerm, 1000);

  const handleChange = (event) => {
    setCoursePerPage(event.target.value);
  };

  useEffect(() => {
    const fetchCourseData = async () => {
      dispatch(fetchCourses(token, 1, coursePerPage, debouncedSearchTerm, true));
    };
    if (!loading) {
      fetchCourseData();
    }
  }, [debouncedSearchTerm, dispatch, coursePerPage]);

  useEffect(() => {
    const fetchCourseData = async () => {
      dispatch(fetchCourses(token, currentPage, coursePerPage, debouncedSearchTerm));
    };
    if (!loading && currentPage > 1) {
      fetchCourseData();
    }
  }, [currentPage, debouncedSearchTerm, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearCourses());
    };
  }, [dispatch]);

  const handleLoadMore = () => {
    if (currentPage < totalPages) {
      dispatch(updateCurrentPage(currentPage + 1));
    }
  };

  const handleSearchChange = (e) => {
    dispatch(setSearchTerm(e.target.value));
    dispatch(updateCurrentPage(1));
  };

  return (
    <div style={{ padding: '20px' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={5}>
        <Typography variant="h5" fontWeight="bold">
          All Courses <Typography component="span">({totalCourses} Courses Available)</Typography>
        </Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <TextField
            variant="outlined"
            placeholder="Search Course"
            size="small"
            onChange={handleSearchChange}
            value={searchTerm}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
      
      <Grid container spacing={2}>
        {courses.map((course, index) => (
          <Grid item xs={12} sm={6} md={4} key={course?._id || index}>
            <CourseCard course={course} index={index} />
          </Grid>
        ))}
      </Grid>

      <Box mt={3} display="flex" justifyContent="end" alignItems="center" gap="10px" textAlign="center">
        <Typography>Page - {currentPage}/{totalPages}</Typography>
        <Button onClick={handleLoadMore} disabled={currentPage >= totalPages} variant="outlined" color="primary">
          Load More
        </Button>
        <FormControl>
          <Select
            value={coursePerPage}
            onChange={handleChange}
            displayEmpty
            size="small"
            renderValue={(value) => `Page Size - ${value}`}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </div>
  );
}

export default App;
