import logo from './logo.svg';
import './App.css';
import DynamicTitle from './routes/Dynamic-Titles';
import Router from './routes/Routes';
import React from 'react';
import {
  Navigate,
  useRoutes,
  BrowserRouter,
  Route,
  Switch,
} from "react-router-dom";

// Pdf file configurations
// import { pdfjs } from 'react-pdf';
// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   "pdfjs-dist/build/pdf.worker.min.js",
//   import.meta.url
// ).toString();
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Router />
        <DynamicTitle/>
      </BrowserRouter>
    </React.Fragment>

  );
}

export default App;
