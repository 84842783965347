import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  TextField,
  InputAdornment,
  Container,
  Menu,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";


import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "use-debounce";


import { clearSurveys, setSearchTerm, setSurveys, updateCurrentPage } from "../../Store/Slices/surveySlice";
import { fetchSurveys } from "../../services/Operations/SurveyOperation";
import { surveyEndpoints } from "../../services/ApiEndPoints";
import { toast } from "react-hot-toast";
import { apiConnector } from "../../services/ApiConnector";


const SurveyPage = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [questionnaireType, setQuestionnaireType] = useState('quiz');

  const handleClick = (event, user) => {
    setAnchorEl(event.currentTarget);
    setSelectedSurvey(user);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedSurvey(null);
  };
  //pagination ...
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Ensure useNavigate is properly initialized
  const { surveys, totalSurveys, currentPage, loading, totalPages, searchTerm } = useSelector((state) => state.surveys);
  const [surveysPerPage, setSurveyPage] = useState(10); // Adjust this number as needed
  const [debouncedSearchTerm] = useDebounce(searchTerm, 1000);

  const handleChange = (event) => {
    setSurveyPage(event.target.value);
  };

  // Effect for handling search term change
  useEffect(() => {
    const fetchSurveyData = async () => {
      dispatch(fetchSurveys(questionnaireType, token, 1, surveysPerPage, debouncedSearchTerm, true)); // Reset schools on new search term
    };

    if (!loading) {
      fetchSurveyData();
    }
  }, [debouncedSearchTerm, dispatch, surveysPerPage, questionnaireType]);

  // Effect for handling pagination
  useEffect(() => {
    const fetchSurveyData = async () => {
      dispatch(fetchSurveys(questionnaireType, token, currentPage, surveysPerPage, debouncedSearchTerm));
    };

    if (!loading && currentPage > 1) {
      fetchSurveyData();
    }
  }, [currentPage, debouncedSearchTerm, dispatch]);

  // Effect for handling component unmount
  useEffect(() => {
    return () => {
      dispatch(clearSurveys());
    };
  }, [dispatch]);

  // Handle Load More functionality
  const handleLoadMore = () => {
    if (currentPage < totalPages) {
      dispatch(updateCurrentPage(currentPage + 1));
    }
  };



  // Handle search term change
  const handleSearchChange = (e) => {
    dispatch(setSearchTerm(e.target.value));
    dispatch(updateCurrentPage(1)); // Reset to first page on new search
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete?")) {
      const toastId = toast.loading("Deleting Assignment!");

      try {
        // Set up the API endpoint for deleting the student
        const endpoint = `${surveyEndpoints.DELETE_SURVEY}/${selectedSurvey._id}`;

        // Make the API call to delete the student
        await apiConnector(
          "DELETE",
          endpoint,
          {},
          { Authorization: `Bearer ${token}` }
        );

        // Show success message
        toast.success("Assignment Deleted Successfully");

        // Filter out the deleted student from the current students list
        const updatedSurvey = surveys.filter((survey) => survey._id !== selectedSurvey._id);

        // Dispatch the updated students data to Redux, assuming payload needs `students`
        dispatch(setSurveys({
          surveys: updatedSurvey,
          totalSurveys: (totalSurveys - 1), // Adjust if needed
          totalPages: totalPages, // Adjust page calculation as per your pagination logic
          currentPage: currentPage, // Keep current page the same
        }));

      } catch (error) {
        console.error("Error deleting Assignment:", error);
        toast.error("Failed to Assignment User");
      } finally {
        toast.dismiss(toastId);
        handleClose(); // Close the menu after deletion
      }
    }
  };
  return (
    <Container sx={{ mt: 4 }}>
      <Box >
        {/* Header Section */}
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Box display={'flex'} flexDirection={'column'} gap={'4px'}>
            <Typography variant="h5" fontWeight="bold">
           Client Assessments <Typography component="span">({totalSurveys} Assessments Available)</Typography>
            </Typography>
            {/* <FormControl sx={{ marginTop: '10px' }} component="fieldset">
              <RadioGroup
                value={questionnaireType} // Controlled component state
                onChange={(e) => setQuestionnaireType(e.target.value)} // Update state function
                row // Optional: Displays radio buttons in a row
              >
                <FormControlLabel value="quiz" control={<Radio />} label="Quiz" />
                <FormControlLabel value="survey" control={<Radio />} label="Survey" />
              </RadioGroup>
            </FormControl> */}

          </Box>
          <Box display="flex" alignItems="center" gap={2}>
            <TextField
              variant="outlined"
              placeholder="Search Assessments"
              value={searchTerm}
              onChange={handleSearchChange}
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="contained"
              color="error"
              startIcon={<AddIcon />}
              sx={{ backgroundColor: "#EF492E" }}
              onClick={() => { navigate('/add-survey') }}
            >
              Create Assessments
            </Button>
          </Box>

        </Box>

        {/* Table Section */}
        <TableContainer component={Paper} sx={{ borderRadius: "8px", border: "1px solid #f5a48b", marginTop: '10px' }}>
          <Table sx={{ tableLayout: 'auto' }}>
            <TableHead>
              <TableRow
                sx={{ backgroundColor: "#FFBE9D" }}
              >
                <TableCell sx={{ fontWeight: 'bold' }}>Sr. No</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Assessments Name</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Type</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>End-Date</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{
              fontSize: "16px", // Increase the font size
              fontWeight: "bold", // Make the text bold
            }}>
              {surveys?.map((survey, index) => (
                <TableRow key={survey.id}>
                  <TableCell>{index + 1}.</TableCell>
                  <TableCell>{survey?.name}</TableCell>
                  <TableCell>{survey?.type}</TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>{survey?.endDate.split('T')[0]}</TableCell>
                  <TableCell>
                    <IconButton onClick={(event) => handleClick(event, survey)}>
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box mt={3} display={'flex'} justifyContent={'end'} alignItems={'center'} gap={'10px'} textAlign="center">
        {/* <Typography>total Schools -{(totalSchools)}</Typography> */}
        <Typography>Page -{currentPage}/{totalPages}</Typography>
        <Button
          onClick={handleLoadMore}
          disabled={currentPage >= totalPages}
          variant="outlined"
          color="primary"
        >
          Load More
        </Button>
        {/* page size */}
        <FormControl>

          <Select
            value={surveysPerPage}
            onChange={handleChange}
            displayEmpty
            size="small"
            renderValue={(value) => `Page Size - ${value}`}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>

        </FormControl>
      </Box>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => {
          console.log("View", selectedSurvey)
          navigate(`/survey/${selectedSurvey?._id}`)
        }}>View</MenuItem>
        <MenuItem onClick={() => {
          navigate(`/survey-clients/${selectedSurvey?._id}`)
        }}>Clients</MenuItem>
        <MenuItem onClick={() => handleDelete(selectedSurvey)}>Delete</MenuItem>
      </Menu>
    </Container>
  );
};

export default SurveyPage;
