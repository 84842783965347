import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography, Button, Accordion, AccordionSummary, AccordionDetails, TextField, Box, Tab, Tabs, Modal, LinearProgress, FormControl, FormControlLabel, RadioGroup, Radio, IconButton, Input, InputLabel, Select, MenuItem } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReactPlayer from 'react-player';
import AddIcon from '@mui/icons-material/Add';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router';
import { toast } from "react-hot-toast";
import { apiConnector } from '../../services/ApiConnector';
import { courseEndpoints } from '../../services/ApiEndPoints';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import TestComponent from './TestComponent';
import LoadingSpinner from '../../common/Spinner';
import ImageReaderModal from '../../common/ImageReaderModal';
import PdfReaderModal from '../../common/PdfReaderModal';



// CoursePage Component: Manages and displays course data and lectures
const CoursePage = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const { id } = useParams();
  const [courseData, setCourseData] = useState(null);
  const [selectedLecture, setSelectedLecture] = useState(null);
  const [lectures, setLectures] = useState(courseData?.lectures || []);
  const [surveys, setSurveys] = useState(courseData?.surveys || []);
  const [tests, setTests] = useState(courseData?.tests || []);
  const [tabIndex, setTabIndex] = useState(0);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [questionnaireType, setQuestionnaireType] = useState('survey')
  const [progress,setProgress] = useState(null);
  const [renderedSurveys, setRenderedSurvey] = useState(null) //survey which is being rendered
  const [loading, setLoading] = useState(false);
  // Variales to handle image and pdf opening in application
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [pdfModalOpen, setPdfModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [attemptText, setAttemptText] = useState('');
  const handleOpenResource = (selectedResource) => {
    // Select image/pdf
    if (selectedResource.type == 'url') {
      window.open(selectedResource?.url, "_blank")
    } else if (selectedResource.type == 'image') {
      setImageModalOpen(true)
      setSelectedImage(selectedResource.url)
    } else if (selectedResource.type == 'pdf') {
      setPdfModalOpen(true)
      setSelectedPdf(selectedResource.url)
    }
  };


  useEffect(() => {
    const filteredSurveys = surveys?.filter((survey) =>
      survey?.type === questionnaireType // Return a boolean from the filter function
    );
    setRenderedSurvey(filteredSurveys);
  }, [surveys, questionnaireType]);

  // fetch the data on page load
  useEffect(() => {
    const fetchCourseDetail = async () => {
      setLoading(true)

      try {
        const response = await apiConnector(
          "GET",
          `${courseEndpoints.GET_COURSE_DETAIL}/${id}`,
          null,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }
        );
        setCourseData(response?.data?.data?.courseDetails);
        setLectures(response?.data?.data?.lectures)
        setSurveys(response?.data?.data?.courseSpecificSurveys)
        setTests(response?.data?.data?.courseSpecificResources)
        setProgress(response?.data?.data?.surveyPassPercentage)
      } catch (error) {
        toast.error(`Failed to load course Detail: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchCourseDetail();
  }, [token, id])
  // When ever the component is being loaded for the first time i will play the first video ....
  useEffect(() => {
    setSelectedLecture(0);
  }, [])
  // Function to handle the selection of a lecture for the video player
  const handleLectureSelect = (index) => {
    setSelectedLecture(index);
  };



  // Function to handle accordion expand/collapse
  const handleAccordionChange = (index) => (event, isExpanded) => {
    setExpandedIndex(isExpanded ? index : null);
  };

  const handleContextMenu = (e) => {
    e.preventDefault(); // Disable right-click
  };


  if (loading) {
    return (
      <LoadingSpinner />
    )
  }


  return (
    <Container maxWidth="lg" sx={{ mb: 4 }}>
      <Typography variant='h5' mt={2} fontWeight={'bold'} mb={2}>{courseData?.name}</Typography>
      <Grid container spacing={4}>
        {/* Video Player and Description Section */}
        <Grid item xs={7}>
          {
            lectures.length == 0 ?
              <img
                src={(courseData?.image) ? (courseData?.image) : `https://ui-avatars.com/api/?name=${courseData?.name}&background=random`}
                alt="Course"
                style={{ width: '100%', height: '350px', objectFit: 'cover', border: '1px solid gray', borderRadius: '20px' }}
              /> :
              <div style={{ position: 'relative', minWidth: '500px', minHeight: '350px', backgroundColor: 'wheat' }}>
                <div onContextMenu={handleContextMenu}>
                  <ReactPlayer
                    url={lectures[selectedLecture]?.url}
                    controls={true}  // Default player controls
                    config={{ file: { attributes: { controlsList: 'nodownload' } } }}  // Disable download button
                    playing={false}
                    width="100%"
                    height="350px"
                  />
                </div>
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: 1,
                    backgroundColor: 'transparent',
                    pointerEvents: 'none', // Allows interaction with the iframe
                  }}
                  onContextMenu={(e) => e.preventDefault()} // Prevents right-click on iframe
                ></div>
              </div>
          }
          <Typography sx={{ fontSize: '20px', fontWeight: '600', mt: 1, ml: 1 }}>{lectures[selectedLecture]?.lectureName}</Typography>
          <Box >
            <Box
              sx={{ padding: '10px' }}
              dangerouslySetInnerHTML={{ __html: lectures[selectedLecture]?.description || 'No description available' }}
            />
          </Box>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="course-description-content"
              id="course-description-header"
            >
              <Typography fontSize={'20px'} fontWeight="bold">Course Description</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{ padding: '10px', }}
                dangerouslySetInnerHTML={{ __html: courseData?.description || "No description found for the course!" }}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>
        {/* Tabs Section */}
        <Grid item xs={5} sx={{ height: '80vh', overflowY: 'scroll',position:'relative',mt:2 }}>
        <Box position="absolute"  top={'-5px'} left={'15%'} display="flex" alignItems="center" borderRadius={'20px'}  width={'80%'} height={30}>
          <LinearProgress
            variant="determinate"
            value={progress}
            style={{ width: '100%', height: '100%' }}
          />
          <Box
            position="absolute"
            left="50%"
            top="50%"
            style={{ transform: 'translate(-50%, -50%)', color: 'white' }}
          >
            <Typography variant="body2" color="inherit">
              {`Course Progress: ${progress}%`}
            </Typography>
          </Box>
        </Box>
          <Tabs
            value={tabIndex}
            onChange={(e, newIndex) => setTabIndex(newIndex)}
            textColor="inherit"
            indicatorColor="#EF492E"
            aria-label="course tabs"
            sx={{ transition: 'background-color 0.3s ease, color 0.3s ease', marginBottom: '10px' }}
          >
            <Tab
              sx={{
                borderRadius: '10px',
                backgroundColor: tabIndex === 0 ? '#EF492E' : 'transparent',
                color: tabIndex === 0 ? 'white' : 'black',
                transition: 'background-color 0.3s ease, color 0.3s ease',
                '&:hover': {
                  backgroundColor: tabIndex === 0 ? '#EF492E' : 'transparent',
                }
              }}
              label="Lectures"
            />
            <Tab
              sx={{
                borderRadius: '10px',
                backgroundColor: tabIndex === 1 ? '#EF492E' : 'transparent',
                color: tabIndex === 1 ? 'white' : 'black',
                transition: 'background-color 0.3s ease, color 0.3s ease',
                '&:hover': {
                  backgroundColor: tabIndex === 1 ? '#EF492E' : 'transparent',
                }
              }}
              label="Assessments"
            />
            <Tab
              sx={{
                borderRadius: '10px',
                backgroundColor: tabIndex === 2 ? '#EF492E' : 'transparent',
                color: tabIndex === 2 ? 'white' : 'black',
                transition: 'background-color 0.3s ease, color 0.3s ease',
                '&:hover': {
                  backgroundColor: tabIndex === 2 ? '#EF492E' : 'transparent',
                }
              }}
              label="Course Resources"
            />
          </Tabs>

          {/* Lecture List Accordion */}
          {tabIndex === 0 && (
            <>
              {
                lectures?.length == 0 && <Box>No Lecture Available</Box>
              }
              {lectures?.map((lecture, index) => (
                <Accordion
                  key={lecture.id}
                  expanded={expandedIndex === index}
                  onChange={handleAccordionChange(index)}
                  sx={{ backgroundColor: expandedIndex === index ? 'wheat' : 'white' }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="subtitle1">
                      {expandedIndex === index && (
                        <span role="img" aria-label="playing">🎬</span>
                      )}
                      {lecture?.lectureName}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {/* Lecture Description and Action Buttons */}
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" width="100%">
                      <Box display="flex" alignItems="center" gap={2}>
                        <Button
                          variant="text"
                          color="primary"
                          onClick={() => handleLectureSelect(index)}
                          startIcon={<PlayCircleIcon />}
                        >
                          Play
                        </Button>
                      </Box>
                    </Box>

                    {/* Display Resources */}
                    {lecture.relatedResources && (
                      <Box mt={2} display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="subtitle2">Course Resources:</Typography>
                      </Box>
                    )}

                    {
                      lecture.relatedResources && lecture.relatedResources.length > 0 && (
                        <Box mt={1}>
                          {lecture.relatedResources.map((resource) => (
                            <Box key={resource._id} display="flex" justifyContent="space-between" alignItems="center" mt={1}>
                              <Button
                                variant="text"
                                color="primary"
                                onClick={() => {
                                  handleOpenResource(resource)
                                }}
                                target="_blank"
                                sx={{
                                  maxWidth: '200px', // Limit width of the button
                                  wordBreak: 'break-word', // Break long words into multiple lines
                                  whiteSpace: 'normal', // Allow text to wrap naturally
                                }}
                              >
                                {resource.name}
                              </Button>
                            </Box>
                          ))}
                        </Box>
                      )
                    }

                    {/* Display Surveys */}
                    {lecture.relatedSurveys && (
                      <Box mt={2} display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="subtitle2">Assessments:</Typography>
                      </Box>
                    )}

                    {/* Lecture-accordian-survey */}
                    {
                      lecture.relatedSurveys && lecture.relatedSurveys.length > 0 && (
                        <Box mt={1}>
                          {lecture.relatedSurveys.map((survey) => (
                            <Box key={survey.id} display="flex" justifyContent="space-between" alignItems="center" mt={1}>
                              <Typography
                                color="primary"
                                sx={{
                                  maxWidth: '200px', // Limit width of the button
                                  wordBreak: 'break-word', // Break long words into multiple lines
                                  whiteSpace: 'normal', // Allow the text to wrap onto the next line
                                }}
                              >
                                {survey.name}
                              </Typography>
                              <Box display="flex" alignItems="center" gap={2}>
                                {
                                  survey?.attemptStatus == 'view' && <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => {
                                      window.open(`/survey-response/${survey?.surveyResponseId}`, "_blank")
                                    }}
                                  >
                                    View
                                  </Button>
                                }
                                {
                                  survey?.attemptStatus == 'attempt' && <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => {
                                      window.open(`/attempt-assessment/${survey?.id}`, "_blank")
                                    }}
                                  >
                                    Attempt
                                  </Button>
                                }
                                {
                                  survey?.attemptStatus == 'reattempt' && <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => {
                                      window.open(`/attempt-assessment/${survey?.id}`, "_blank")
                                    }}
                                  >
                                    Re-Attempt
                                  </Button>
                                }
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      )
                    }

                  </AccordionDetails>
                </Accordion>
              ))}
            </>
          )}
          {/* All Surveys Related to course */}
          {tabIndex === 1 && (
            <Box sx={{ height: '80vh', display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <FormControl sx={{ marginX: 'auto' }} component="fieldset">
                <RadioGroup
                  value={questionnaireType} // Controlled component state
                  onChange={(e) => setQuestionnaireType(e.target.value)} // Update state function
                  row // Optional: Displays radio buttons in a row
                >
                  <FormControlLabel value="quiz" control={<Radio />} label="Quiz" />
                  <FormControlLabel value="survey" control={<Radio />} label="Survey" />
                </RadioGroup>
              </FormControl>

              {
                renderedSurveys?.map((survey, index) => {
                  return (
                    <Box
                      key={index}
                      sx={{
                        border: '1px solid #E0E0E0',
                        borderRadius: '12px',
                        padding: '16px',
                        backgroundColor: '#F9F9F9',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        display: 'flex',
                        flexDirection: 'column',

                        justifyContent: 'space-between',
                        gap: '16px',
                        transition: 'transform 0.2s',
                        ":hover": {
                          transform: 'translateY(-4px)',
                          boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)'
                        }
                      }}
                    >
                      <Box sx={{ flexGrow: 1 }}>
                        <Typography fontSize={'20px'} sx={{ color: '#333' }}>
                          {survey?.name}
                        </Typography>
                        <Typography fontSize={'14px'} sx={{ color: '#666', mb: '4px' }}>
                          {survey?.description.slice(0, 50)}...
                        </Typography>
                        <Typography fontSize={'12px'} sx={{ color: '#888' }}>
                          Ends on: {new Date(survey?.endDate).toLocaleDateString()}
                        </Typography>
                      </Box>

                      <Box sx={{ display: 'flex', gap: '8px' }}>
                        {
                          survey?.attemptStatus == 'view' && <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              window.open(`/survey-response/${survey?.surveyResponseId}`, "_blank")
                            }}
                          >
                            View
                          </Button>
                        }
                        {
                          survey?.attemptStatus == 'attempt' && <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              window.open(`/attempt-assessment/${survey?.id}`, "_blank")
                            }}
                          >
                            Attempt
                          </Button>
                        }
                        {
                          survey?.attemptStatus == 'reattempt' && <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              window.open(`/attempt-assessment/${survey?.id}`, "_blank")
                            }}
                          >
                            Re-Attempt
                          </Button>
                        }
                      </Box>
                    </Box>
                  )
                })
              }
            </Box>
          )}
          {/* All Resources Related To Course */}
          {tabIndex === 2 && (
            <TestComponent tests={tests} courseId={id} setTests={setTests} />
          )}
        </Grid>
      </Grid>

      {/* Image and pdf opener modal  */}
      {
        imageModalOpen && <ImageReaderModal selectedImage={selectedImage} setImageModalOpen={setImageModalOpen} />
      }
      {
        pdfModalOpen && <PdfReaderModal selectedPdf={selectedPdf} setPdfModalOpen={setPdfModalOpen} />
      }
    </Container>
  );
};

export default CoursePage;

