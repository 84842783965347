import React, { useState,useEffect } from 'react';
import { Avatar, Box, Button, Container, Typography, Paper, CardMedia, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Input } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import { authEndpoints, counselorEndpoints } from '../../services/ApiEndPoints';
import { apiConnector } from '../../services/ApiConnector';
import EditIcon from '@mui/icons-material/Edit';
import useAwsFileUpload from "../../hooks/useAwsImageUpload";
import { setUser } from "../../Store/Slices/profileSlice"

const Profile = () => {
  const dispatch = useDispatch();
  const {uploadFileToAws,uploading} = useAwsFileUpload();
  const token = useSelector((state) => state.auth.token);
  const { user } = useSelector((state) => state.profile);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  // Handle opening and closing the image modal
  const handleImageModalOpen = () => setOpenImageModal(true);
  const handleImageModalClose = () => setOpenImageModal(false);
  // State for modal visibility
  const [open, setOpen] = useState(false);

  const handleImageChange = (e) => {
    setSelectedImage(e.target.files[0]);
  };

  const handleImageSubmit = async () => {
    if (!selectedImage) {
      toast.error("Please select an image");
      return;
    }
    const toastId = toast.loading("Updating Profile Picture...");
    try {
       let imageUrl = await uploadFileToAws(selectedImage);
        if (!imageUrl) {
          throw new Error("Failed to upload image");
        }
      const response = await apiConnector(
        "POST",
        `${counselorEndpoints.UPDATE_COUNSELOR_DETAIL}`,  // Replace with your actual endpoint
        {imageUrl},
        {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        }
      );

      if (response?.data?.status) {
        toast.success("Profile picture updated successfully!");
        setSelectedImage(null);
        handleImageModalClose();
      } else {
        throw new Error("Failed to update profile picture");
      }
    } catch (error) {
      toast.error(`Failed to update profile picture: ${error.message}`);
    } finally {
      toast.dismiss(toastId);
    }
  };
  
  // State for passwords
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  // Handle opening and closing the modal
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = async () => {
    if (password === "" || confirmPassword === "") {
      toast.error("Password and Confirm Password fields can't be empty!");
      return;
    }
  
    if (password !== confirmPassword) {
      toast.error("Password and Confirm Password should be the same!");
      return;
    }
    handleClose();

    const toastId = toast.loading("Updating Password...");
  
    try {
      const data = { newPassword: password };
  
      const response = await apiConnector(
        "POST",
        `${authEndpoints.RESET_PASSWORD}`,
        data,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );

      if (response?.data?.status) {
        toast.success("Password updated successfully!");
        handleClose();
      } else {
        throw new Error("Failed to update password");
      }
    } catch (error) {
      toast.error(`Failed to update password: ${error.message}`);
    } finally {
      toast.dismiss(toastId);
    }
  };

  // const fetchProfile = async () => {
  //   const toastId = toast.loading("Fetching Profile...");
  //   try {
  //     const response = await apiConnector(
  //       "GET",
  //       `${authEndpoints.PROFILE}`, // Ensure the endpoint is correct for fetching profile
  //       null,
  //       {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       }
  //     );
  //     console.log(response)
  //     if (response?.data?.status) { 
  //       toast.success("Profile fetched successfully!");
  //       dispatch(setUser({ ...response?.data?.data}))

  //     localStorage.setItem("token", JSON.stringify(response?.data?.data?.token))
  //     localStorage.setItem("user", JSON.stringify(response?.data?.data))
  //     } else {
  //       throw new Error("Failed to fetch profile");
  //     }
  //   } catch (error) {
  //     toast.error(`Failed to fetch profile: ${error.message}`);
  //   } finally {
  //     toast.dismiss(toastId);
  //   }
  // };

  // useEffect(() => {
  //   console.log("Profile data:");
  //   fetchProfile();
  // }, []);

  return (
    <Container>
      <Paper elevation={3} sx={{ minHeight: '80vh', padding: '2rem', marginTop: '2rem', borderRadius: '10px' }}>
        {/* Profile Header */}
        <Box
          sx={{
            borderBottom: '2px solid #FFD9CD',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '2rem',
          }}
        >
          <Typography variant="h4" color="#EF492E" sx={{ mb: '4px', fontWeight: 600 }}>
            Profile
          </Typography>
        </Box>

        {/* Profile Picture and Name */}
        <Box sx={{ textAlign: 'center', marginBottom: '2rem' }}>
        <Box sx={{position:'relative' }}>
          <Avatar sx={{ width: 120, height: 120, marginBottom: '1rem',zIndex:10 }}>
            <CardMedia
              component="img"
              sx={{ width: 120, height: 120, borderRadius: '50%' }}
              image={user?.profilePhoto || `https://ui-avatars.com/api/?name=${user?.name}&background=random`}
              alt="Profile Image"
            />
          </Avatar>
          <IconButton
            color='white'
            sx={{ position: 'absolute', top: ['70px'], left: '9%',zIndex:20,backgroundColor:'gray',":hover":{backgroundColor:'gray'} }}
            onClick={handleImageModalOpen}
          >
            <EditIcon />
          </IconButton>
          </Box>
          <Typography textAlign={'start'} variant="h5" sx={{ fontWeight: 600 }}>
            {user?.name}
          </Typography>
        </Box>

        {/* Personal Details in Columns */}
        <Grid container spacing={4} sx={{ marginBottom: '2rem' }}>
          {/* Column 1 */}
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" sx={{ fontWeight: 600 }}>Personal Details</Typography>
            <Typography variant="body1" sx={{ marginBottom: '0.5rem' }}>
              <strong>Contact No:</strong> {user?.phone}
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '0.5rem' }}>
              <strong>Email ID:</strong> {user?.email}
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '0.5rem' }}>
              <strong>Role:</strong> {user?.role=='counceller'?"counsellor":user?.role}
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '0.5rem' }}>
              <strong>Max Allowed Students:</strong> {user?.maxAllowedStudent}
            </Typography>
            {/* <Typography variant="body1" sx={{ marginBottom: '0.5rem' }}>
              <strong>Status:</strong> {user?.status ? 'Active' : 'Inactive'}
            </Typography> */}
            {/* <Typography variant="body1" sx={{ marginBottom: '0.5rem' }}>
              <strong>Brand Name:</strong> {user?.brandId?.title}
            </Typography> */}
            <Button variant="outlined" onClick={handleClickOpen}>Update Password</Button>
          </Grid>

          {/* Column 2 */}
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" sx={{ fontWeight: 600 }}>Address Details</Typography>
            <Typography variant="body1" sx={{ marginBottom: '0.5rem' }}>
              <strong>Address:</strong> {user?.address}
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '0.5rem' }}>
              <strong>City:</strong> {user?.city}
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '0.5rem' }}>
              <strong>State:</strong> {user?.state}
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '0.5rem' }}>
              <strong>Landmark:</strong> {user?.landmark}
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '0.5rem' }}>
              <strong>Pincode:</strong> {user?.pincode}
            </Typography>
          </Grid>
        </Grid>

        {/* Permissions */}
        {/* <Box sx={{ marginBottom: '2rem' }}>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>Permissions</Typography>
          <Box display={'flex'} flexDirection={'row'} gap={'5px'}>
          {user?.permission?.map((item)=>(
            <Box sx={{border:'1px solid #FFD9CD',padding:'0.5rem'}} >{item}</Box>
          ))}
          </Box>
        </Box> */}

        {/* Password Update Modal */}
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Update Password</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              label="New Password"
              type="password"
              fullWidth
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              margin="dense"
              label="Confirm Password"
              type="password"
              fullWidth
              variant="outlined"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">Cancel</Button>
            <Button onClick={handleSubmit} color="primary">Submit</Button>
          </DialogActions>
        </Dialog>
         {/* Profile update modal */}
        <Dialog open={openImageModal} onClose={handleImageModalClose}>
          <DialogTitle>Update Profile Picture</DialogTitle>
          <DialogContent>
            <Input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleImageModalClose} color="secondary">Cancel</Button>
            <Button onClick={handleImageSubmit} color="primary">Submit</Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </Container>
  );
};

export default Profile;
