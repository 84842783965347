import React, { useState } from 'react';
import { Box, Button, Typography, Modal } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ImageReaderModal from '../../common/ImageReaderModal';
import PdfReaderModal from '../../common/PdfReaderModal';

const TestComponent = ({ tests }) => {
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [pdfModalOpen, setPdfModalOpen] = useState(false);
  const [selectedImage,setSelectedImage] = useState(null);
  const [selectedPdf,setSelectedPdf] = useState(null);
  const handleOpenResource = (test) => {
    // Select image/pdf
    if (test.type == 'url') {
          window.open(test?.url, "_blank")
        } else if (test.type == 'image') {
          setImageModalOpen(true)
          setSelectedImage(test.url)
        } else if (test.type == 'pdf') {
          setPdfModalOpen(true)
          setSelectedPdf(test.url)
        }
    };

  return (
    <Box sx={{height:'70vh'}}>
      {tests?.map((test, index) => (
        <Box key={index} sx={{ mb: 2, p: 2, border: '1px solid #ccc', borderRadius: '4px' }}>
          <Typography variant="h6" sx={{ color: '#EF492E' }}>{test.name.slice(0, 30)}...</Typography>
          <Typography variant="body1">{test.description.slice(0, 50)}...</Typography>
          <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
            <Button width={'30%'} variant="outlined" startIcon={<VisibilityIcon />} sx={{ color: "#EF492E" }} 
            onClick={()=>{handleOpenResource(test)}}>
              Open
            </Button>
          </Box>
        </Box>
      ))}
        {/* Image Modal */}
        {
        imageModalOpen && <ImageReaderModal  selectedImage={selectedImage} setImageModalOpen={setImageModalOpen}/>
      }
      {/* Pdf Modal */}
      {
        pdfModalOpen && <PdfReaderModal selectedPdf={selectedPdf} setPdfModalOpen={setPdfModalOpen}/>
      }
   </Box>
  );
};

export default TestComponent;