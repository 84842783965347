import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { FaRegUserCircle } from 'react-icons/fa';
import LogoutIcon from '@mui/icons-material/Logout';

import {logout} from '../../services/Operations/AuthOperations';
import { useDispatch } from 'react-redux';

const ProfileCard = ({ setOpenProfile, user }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setOpenLogOut] = useState(false);

  const handleLogOut = () => {
    setOpenLogOut(true);
  };

  const navigateToProfile = () => {
    setOpenProfile(false);
    navigate('/profile');
  };

  const handleClose = () => {
    setOpenLogOut(false);
  };

  const handleConfirm = () => {
    setOpenLogOut(false);
    dispatch(logout(navigate));
  };

  console.log(user)

  return (
    <Card sx={{ position: 'absolute', top: '60px',right:'-10px', zIndex: 10, minWidth: 230, minHeight: 150, border: '1px solid #FF6636', borderRadius: '16px' }}>
      <CardContent>
        <Box sx={{ display: 'flex', borderBottom: '1px solid #FFD9CD', py: 2 }}>
          <CardMedia
            component="img"
            sx={{ width: 60, height: 60, borderRadius: '50%' }}
            image={user?.profilePhoto || `https://ui-avatars.com/api/?name=${user?.name}&background=random`}
            alt="profileImage"
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', ml: 2 }}>
            <Typography variant="h6" component="div" sx={{ fontWeight: 'bold',lineHeight:'22px' }}>
              {user?.name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {user?.role=='counceller'?"counsellor":user?.role}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ display: 'flex', alignItems: 'center', py: 1, cursor: 'pointer' }} onClick={navigateToProfile}>
          <FaRegUserCircle size={23} color="#EF492E" />
          <Typography variant="body1" sx={{ ml: 1 }} onClick={()=>{navigate('/profile')}}>
            Profile
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ display: 'flex', alignItems: 'center', py: 1, cursor: 'pointer' }} onClick={handleLogOut}>
          <LogoutIcon sx={{ color: '#EF492E', mr: 1 }} />
          <Typography variant="body1" sx={{ color: '#EF492E' }}>
            Log Out
          </Typography>
        </Box>
      </CardContent>
      <Dialog
        open={isOpen}
        onClose={handleClose}
      >
        <DialogTitle>Confirm Logout</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to log out?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleConfirm} color="error">Log Out</Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default ProfileCard;
