import { setLoading, setToken } from "../../Store/Slices/authSlice";
import { setUser } from "../../Store/Slices/profileSlice"
import { apiConnector } from "../ApiConnector.js"
import { authEndpoints } from "../ApiEndPoints.js"
import { toast } from "react-hot-toast"

const {
  LOGIN_API,
} = authEndpoints


export function login(email, password,role, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading ...")
    dispatch(setLoading(true))
    try {
      const response = await apiConnector("POST", LOGIN_API, {
        email,
        password,
        role
      })
      if (!(response.data.status)) {
        throw new Error(response.data.message)
      }
      
      toast.success("Login Successful")
      dispatch(setToken(response.data.data.token))
      const userImage = response?.data?.data?.profilePhoto
        ? response?.data?.data?.profilePhoto
        : `https://api.dicebear.com/5.x/initials/svg?seed=${response?.data?.data?.name}`
        
      dispatch(setUser({ ...response?.data?.data, image: userImage }))

      localStorage.setItem("token", JSON.stringify(response?.data?.data?.token))
      localStorage.setItem("user", JSON.stringify(response?.data?.data))
      navigate("/")
    } catch (error) {
      console.log(error);
      toast.error(`Error! ${error?.response?.data?.message}`)
    }
    dispatch(setLoading(false))
    toast.dismiss(toastId)
  }
}

export function logout(navigate) {
  return (dispatch) => {
    dispatch(setToken(null))
    dispatch(setUser(null))
    localStorage.removeItem("token")
    localStorage.removeItem("user")
    toast.success("Logged Out")
    navigate("/login")
  }
}


