// import React, { useState } from 'react';
// import { Box, Typography, TextField, RadioGroup, FormControlLabel, Radio, Checkbox, IconButton, Button, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
// import DeleteIcon from '@mui/icons-material/Delete';
// import ModeEditIcon from '@mui/icons-material/ModeEdit';
// import AddIcon from '@mui/icons-material/Add';
// import { apiConnector } from '../../services/ApiConnector';
// import { surveyEndpoints } from '../../services/ApiEndPoints';
// import { useSelector } from 'react-redux';
// import { toast } from 'react-hot-toast'



// const RenderQuestions = ({ questionaireType, questions, handleDeleteQuestion, setQuestions }) => {
//   const [editingIndex, setEditingIndex] = useState(null);
//   const [required, setRequired] = useState(false);
//   const token = useSelector((state) => state.auth.token);
//   const handleEditQuestion = (index) => {
//     console.log(index)
//     setEditingIndex(index);
//   };

//   console.log(questions)


//   const handleSaveQuestion = async (index) => {
//     const toastId = toast.loading("Updating Question, Please Wait...");
//     const questionId = questions[index]?._id;

//     if (!questionId) {
//       toast.error("Question ID is missing. Please refresh the page and try again.");
//       return;
//     }
//     const questionData = questions[index]
//     const data = {
//       title: questionData.title,
//       options: questionData.options,
//       type: questionData.type,
//       required: questionData.required,
//       correctAnswer:questionData?.correctAnswer,
//       negativeMark: questionData?.negativeMark,
//       questionMark:questionData?.questionMark,
//       attemptCount:questionData?.attemptCount
//     }
//     try {
//       const response = await apiConnector(
//         "PUT",
//         `${surveyEndpoints.UPDATE_QUESTION}/${questionId}`,
//         data,
//         {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${token}`,
//         }
//       );

//       if (response?.data?.status) {
//         setEditingIndex(null);
//         questions[index] = response?.data?.data;
//         toast.success("Question updated successfully.");
//       } else {
//         toast.error("Failed to update the question. Please try again.");
//       }

//     } catch (error) {
//       toast.error(`Failed to update question: ${error.message}`);
//     } finally {
//       toast.dismiss(toastId);
//     }
//   };


//   const handleQuestionTextChange = (index, event) => {
//     const newQuestions = [...questions];
//     newQuestions[index].title = event.target.value;
//     setQuestions(newQuestions);
//   };

//   const handleOptionChange = (qIndex, oIndex, event) => {
//     const newQuestions = [...questions];
//     newQuestions[qIndex].options[oIndex].value = event.target.value;
//     setQuestions(newQuestions);
//   };

//   const handleAddOption = (index) => {
//     const newQuestions = [...questions];
//     newQuestions[index].options.push({ value: '' });
//     setQuestions(newQuestions);
//   };

//   const handleDeleteOption = (qIndex, oIndex) => {
//     const newQuestions = [...questions];
//     newQuestions[qIndex].options.splice(oIndex, 1);
//     setQuestions(newQuestions);
//   };

//   const handleQuestionTypeChange = (index, event) => {
//     const newQuestions = [...questions];
//     newQuestions[index].type = event.target.value;
//     // Reset options if the type is changed to 'Short-answer' or 'Brief-answer'
//     if (newQuestions[index].type === 'Short-answer' || newQuestions[index].type === 'Brief-answer') {
//       newQuestions[index].options = [];
//     }
//     setQuestions(newQuestions);
//   };

//   const handleRequiredChange = (index, event) => {
//     const newQuestions = [...questions];
//     newQuestions[index].required = event.target.value;
//     setQuestions(newQuestions);
//   };

//   // Special things only accessible in quiz ...
//   const handleQuestionMark = (index, event) => {
//     const newQuestions = [...questions];
//     newQuestions[index].questionMark = event.target.value;
//     setQuestions(newQuestions);
//   }
//   const handleNegativeMarkChange = (index, event) => {
//     const newQuestions = [...questions];
//     newQuestions[index].negativeMark = event.target.value;
//     setQuestions(newQuestions);
//   }
//   const correctOptionChange = (index, event) => {
//     const newQuestions = [...questions];
//     newQuestions[index].correctAnswer = event.target.value;
//     setQuestions(newQuestions);
//   }

//   const attemptCountChange = (index, event) => {
//     const newQuestions = [...questions];
//     newQuestions[index].attemptCount = event.target.value;
//     setQuestions(newQuestions);
//   }

//   return (
//     <Box sx={{ width: '100%', marginX: 'auto' }}>
//       {questions?.map((question, index) => (
//         <Box key={index} mb={4} p={3} border="1px solid #ccc" borderRadius="8px" sx={{ marginX: 'auto', marginTop: '10px', width: '90%', backgroundColor: 'white' }}>
//           {/* TOp Level Element question number and function */}
//           <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>

//             <Typography sx={{ fontWeight: '600', fontSize: "15px" }}>{`Question ${index + 1}`}</Typography>
//             <Box sx={{ width: '50%', display: 'flex', justifyContent: (editingIndex == index ? 'center' : 'end') }}>
//               {editingIndex === index ? (
//                 <>
//                   {/* Required Or Not selected */}
//                   <FormControl >
//                     <InputLabel id="required-label">Required</InputLabel>
//                     <Select
//                       size='small'
//                       name='required'
//                       labelId="required-label"
//                       id="required"
//                       value={question?.required}
//                       label="Required"
//                       onChange={(e) => { handleRequiredChange(index, e) }}
//                     >
//                       <MenuItem value={true}>True</MenuItem>
//                       <MenuItem value={false}>False</MenuItem>
//                     </Select>
//                   </FormControl>
//                   {/* Question Type Selection */}
//                   <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px', width: '50%', marginLeft: '10px' }}>
//                     <Select
//                       size='small'
//                       sx={{ width: '70%' }}
//                       variant="outlined"
//                       value={question.type}
//                       onChange={(e) => handleQuestionTypeChange(index, e)}
//                     >
//                       {
//                         questionaireType == 'quiz' ? <MenuItem value="Single-choice">Single-choice</MenuItem> : <>
//                           <MenuItem value="Multiple-choice">Multiple-choice</MenuItem>
//                           <MenuItem value="Single-choice">Single-choice</MenuItem>
//                           <MenuItem value="Rating">Rating</MenuItem>
//                           <MenuItem value="Agree-disagree">Agree-disagree</MenuItem>
//                           <MenuItem value="Short-answer">Short-answer</MenuItem>
//                           <MenuItem value="Brief-answer">Brief-answer</MenuItem>
//                         </>
//                       }
//                     </Select>
//                     <IconButton onClick={() => handleSaveQuestion(index)}>
//                       <Typography sx={{ color: '#F84135', fontWeight: 'bold' }}>Save</Typography>
//                     </IconButton>
//                   </Box>
//                 </>
//               ) : (
//                 <IconButton onClick={() => handleEditQuestion(index)}>
//                   <ModeEditIcon sx={{ color: '#F84135' }} />{/* Edit */}
//                 </IconButton>
//               )}
//               <IconButton onClick={() => handleDeleteQuestion(index)}>
//                 <DeleteIcon sx={{ color: '#F84135' }} />{/* Delete */}
//               </IconButton>
//             </Box>
//           </Box>

//           {editingIndex === index ? (
//             <>
//               <TextField
//                 required={question?.required}
//                 fullWidth
//                 variant="outlined"
//                 value={`${question?.title}`}
//                 onChange={(e) => handleQuestionTextChange(index, e)}
//                 sx={{ marginBottom: '10px' }}
//               />
//             </>
//           ) : (
//             <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//               <Typography variant="body1" gutterBottom>
//                 {`${question?.title}   ${(question?.required) ? '***' : ''}`}
//               </Typography>
//               <Typography variant="body2" gutterBottom color="textSecondary">
//                 {`Type: ${question.type}`}
//               </Typography>
//             </Box>
//           )}

//           {/* Render Based on Question Type */}
//           {(question.type === 'Multiple-choice' || question.type === 'Single-choice') && (
//             <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
//               {question.options.map((option, idx) => (
//                 <Box key={idx} sx={{ width: '50%', display: 'flex', alignItems: 'center', boxSizing: 'border-box', padding: '8px' }}>
//                   {editingIndex === index ? (
//                     <TextField
//                       fullWidth
//                       variant="outlined"
//                       value={option.value}
//                       onChange={(e) => handleOptionChange(index, idx, e)}
//                       sx={{ marginRight: '8px' }}
//                     />
//                   ) : (
//                     <FormControlLabel
//                       control={question.type === 'Multiple-choice' ? <Checkbox disabled /> : <Radio disabled />}
//                       label={option.value}
//                       sx={{ width: '100%' }}
//                     />
//                   )}
//                   {editingIndex === index && (
//                     <IconButton onClick={() => handleDeleteOption(index, idx)}>
//                       <DeleteIcon sx={{ color: '#F84135' }} />
//                     </IconButton>
//                   )}
//                 </Box>
//               ))}
//               {editingIndex === index && (
//                 <Button startIcon={<AddIcon />} onClick={() => handleAddOption(index)} sx={{ marginTop: '8px' }}>
//                   Add Option
//                 </Button>
//               )}
//             </Box>
//           )}

//           {question.type === 'Rating' && (
//             <RadioGroup row>
//               {[1, 2, 3, 4, 5].map((value) => (
//                 <FormControlLabel
//                   key={value}
//                   control={<Radio disabled />}
//                   label={value}
//                 />
//               ))}
//             </RadioGroup>
//           )}

//           {question.type === 'Agree-disagree' && (
//             <RadioGroup row>
//               <FormControlLabel
//                 control={<Radio disabled />}
//                 label="Agree"
//               />
//               <FormControlLabel
//                 control={<Radio disabled />}
//                 label="Disagree"
//               />
//             </RadioGroup>
//           )}

//           {(question.type === 'Short-answer' || question.type === 'Brief-answer') && (
//             <TextField
//               fullWidth
//               variant="outlined"
//               multiline={question.type === 'Brief-answer'}
//               rows={question.type === 'Brief-answer' ? 4 : 1}
//               value={question.title}
//               onChange={(e) => handleQuestionTextChange(index, e)}
//               disabled={editingIndex !== index}
//             />
//           )}

//           {
//             questionaireType == 'quiz' && (
//               <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
//                 {/* Attempt */}
//                 {editingIndex === index ? (
//                   <>
//                     <FormControl sx={{ marginBottom: '10px' ,width:'30%'}} >
//                       <InputLabel id="correct-answer-label">Correct Answer</InputLabel>
//                       <Select
//                         labelId="correct-answer-label"
//                         id="correct-answer"
//                         value={question?.correctAnswer}
//                         label="Correct Answer"
//                         onChange={(e) => correctOptionChange(index, e)}
//                       >
//                         {question?.options?.map((option, idx) => (
//                           <MenuItem key={idx} value={option.value}>
//                             {option.value}
//                           </MenuItem>
//                         ))}
//                       </Select>
//                     </FormControl>

//                   </>
//                 ) : (
//                   <Box sx={{ width: '100%', display: 'flex', gap: '10px', justifyContent: 'start', alignItems: 'center' }}>
//                     <Typography variant="body1" gutterBottom>
//                       Correct Answer:
//                     </Typography>
//                     <Typography variant="body2" gutterBottom color="textSecondary">
//                       {`${question?.correctAnswer}`}
//                     </Typography>
//                   </Box>
//                 )}
//                 {/* Marks of Question */}
//                 {editingIndex === index ? (
//                   <>
//                     <TextField
                      
                      
//                       label="Question-Mark"
//                       variant="outlined"
//                       value={`${question?.questionMark}`}
//                       onChange={(e) => handleQuestionMark(index, e)}
//                       sx={{ marginBottom: '10px' ,width:'30%'}}
//                     />
//                   </>
//                 ) : (
//                   <Box sx={{ width: '100%', display: 'flex', gap: '10px', justifyContent: 'start', alignItems: 'center' }}>
//                     <Typography variant="body1" gutterBottom>
//                       Mark:
//                     </Typography>
//                     <Typography variant="body2" gutterBottom color="textSecondary">
//                       {`${question?.questionMark}`}
//                     </Typography>
//                   </Box>
//                 )}
//                 {/* Negative Marks */}
//                 {editingIndex === index ? (
//                   <>
//                     <TextField
                      
//                       label="Negative-Mark"
//                       variant="outlined"
//                       value={`${question?.negativeMark}`}
//                       onChange={(e) => handleNegativeMarkChange(index, e)}
//                       sx={{ marginBottom: '10px' ,width:'30%'}}
//                     />
//                   </>
//                 ) : (
//                   <Box sx={{ width: '100%', display: 'flex', gap: '10px', justifyContent: 'start', alignItems: 'center' }}>
//                     <Typography variant="body1" gutterBottom>
//                       Negative Mark:
//                     </Typography>
//                     <Typography variant="body2" gutterBottom color="textSecondary">
//                       {`${question?.negativeMark}`}
//                     </Typography>
//                   </Box>
//                 )}
//                 {/* Attempt Number */}
//                 {editingIndex === index ? (
//                   <>
//                     <TextField
//                       label="attemptCount"
//                       variant="outlined"
//                       value={`${question?.attemptCount}`}
//                       onChange={(e) => attemptCountChange(index, e)}
//                       sx={{ marginBottom: '10px' ,width:'30%'}}
//                     />
//                   </>
//                 ) : (
//                   <Box sx={{ width: '100%', display: 'flex', gap: '10px', justifyContent: 'start', alignItems: 'center' }}>
//                     <Typography variant="body1" gutterBottom>
//                       Attempt-Number:
//                     </Typography>
//                     <Typography variant="body2" gutterBottom color="textSecondary">
//                       {`${question?.attemptCount}`}
//                     </Typography>
//                   </Box>
//                 )}
//               </Box>
//             )
//           }
//         </Box>
//       ))}
//     </Box>
//   );
// };

// export default RenderQuestions;

import React, { useState } from 'react';
import { Box, Typography, TextField, RadioGroup, FormControlLabel, Radio, Checkbox, IconButton, Button, Select, MenuItem, FormControl, InputLabel, Input } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import AddIcon from '@mui/icons-material/Add';
import { apiConnector } from '../../services/ApiConnector';
import { surveyEndpoints } from '../../services/ApiEndPoints';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast'



const RenderQuestions = ({ questionaireType, questions, handleDeleteQuestion, setQuestions }) => {
  const [editingIndex, setEditingIndex] = useState(null);
  const [required, setRequired] = useState(false);
  const token = useSelector((state) => state.auth.token);
  const handleEditQuestion = (index) => {
    console.log(index)
    setEditingIndex(index);
  };

  console.log(questions)


  const handleSaveQuestion = async (index) => {
    const toastId = toast.loading("Updating Question, Please Wait...");
    const questionId = questions[index]?._id;

    if (!questionId) {
      toast.error("Question ID is missing. Please refresh the page and try again.");
      return;
    }
    const questionData = questions[index]
    const data = {
      title: questionData.title,
      options: questionData.options,
      type: questionData.type,
      required: questionData.required,
      correctAnswer: questionData?.correctAnswer,
      negativeMark: questionData?.negativeMark,
      questionMark: questionData?.questionMark,
      attemptCount: questionData?.attemptCount,
      ansLength: (questionData?.type == 'Brief-answer') ? (questionData?.ansLength) : ""
    }
    try {
      const response = await apiConnector(
        "PUT",
        `${surveyEndpoints.UPDATE_QUESTION}/${questionId}`,
        data,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );

      if (response?.data?.status) {
        setEditingIndex(null);
        questions[index] = response?.data?.data;
        toast.success("Question updated successfully.");
      } else {
        toast.error("Failed to update the question. Please try again.");
      }

    } catch (error) {
      toast.error(`Failed to update question: ${error.message}`);
    } finally {
      toast.dismiss(toastId);
    }
  };


  const handleQuestionTextChange = (index, event) => {
    const newQuestions = [...questions];
    newQuestions[index].title = event.target.value;
    setQuestions(newQuestions);
  };

  const handleOptionChange = (qIndex, oIndex, event) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].options[oIndex].value = event.target.value;
    setQuestions(newQuestions);
  };

  const handleAddOption = (index) => {
    const newQuestions = [...questions];
    newQuestions[index].options.push({ value: '' });
    setQuestions(newQuestions);
  };

  const handleDeleteOption = (qIndex, oIndex) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].options.splice(oIndex, 1);
    setQuestions(newQuestions);
  };

  const handleQuestionTypeChange = (index, event) => {
    const newQuestions = [...questions];
    newQuestions[index].type = event.target.value;
    // Reset options if the type is changed to 'Short-answer' or 'Brief-answer'
    if (newQuestions[index].type === 'Short-answer' || newQuestions[index].type === 'Brief-answer') {
      newQuestions[index].options = [];
    }
    setQuestions(newQuestions);
  };

  const handleRequiredChange = (index, event) => {
    const newQuestions = [...questions];
    newQuestions[index].required = event.target.value;
    setQuestions(newQuestions);
  };

  // Special things only accessible in quiz ...
  const handleQuestionMark = (index, event) => {
    const newQuestions = [...questions];
    newQuestions[index].questionMark = event.target.value;
    setQuestions(newQuestions);
  }
  const handleNegativeMarkChange = (index, event) => {
    const newQuestions = [...questions];
    newQuestions[index].negativeMark = event.target.value;
    setQuestions(newQuestions);
  }
  const correctOptionChange = (index, event) => {
    const newQuestions = [...questions];
    newQuestions[index].correctAnswer = event.target.value;
    setQuestions(newQuestions);
  }

  const attemptCountChange = (index, event) => {
    const newQuestions = [...questions];
    newQuestions[index].attemptCount = event.target.value;
    setQuestions(newQuestions);
  }

  const handleAnsLengthChange = (index, event) => {
    const newQuestions = [...questions];
    newQuestions[index].ansLength = event.target.value;
    setQuestions(newQuestions);
  }

  return (
    <Box sx={{ width: '100%', marginX: 'auto' }}>
      {questions?.map((question, index) => (
        <Box key={index} mb={4} p={3} border="1px solid #ccc" borderRadius="8px" sx={{ marginX: 'auto', marginTop: '10px', width: '90%', backgroundColor: 'white' }}>
          {/* TOp Level Element question number and function */}
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>

            <Typography sx={{ fontWeight: '600', fontSize: "15px" }}>{`Question ${index + 1}`}</Typography>
            <Box sx={{ width: '50%', display: 'flex', justifyContent: (editingIndex == index ? 'center' : 'end') }}>
              {editingIndex === index ? (
                <>
                  {/* Required Or Not selected */}
                  {
                    question?.type == 'Brief-answer' && (<TextField sx={{mr:2}}
                      type='text'
                      size='small'
                      name='ansLength'
                      variant="outlined"
                      id="ansLength"
                      value={question?.ansLength}
                      label="ansLength"
                      onChange={(e) => { handleAnsLengthChange(index, e) }}
                    />)

                 
                  }
                  {
                    <FormControl >
                      <InputLabel id="required-label">Required</InputLabel>
                      <Select
                        size='small'
                        name='required'
                        labelId="required-label"
                        id="required"
                        value={question?.required}
                        label="Required"
                        onChange={(e) => { handleRequiredChange(index, e) }}
                      >
                        <MenuItem value={true}>True</MenuItem>
                        <MenuItem value={false}>False</MenuItem>
                      </Select>
                    </FormControl>
                  }

                  {/* Question Type Selection */}
                  <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px', width: '50%', marginLeft: '10px' }}>
                    <Select
                      size='small'
                      sx={{ width: '70%' }}
                      variant="outlined"
                      value={question.type}
                      onChange={(e) => handleQuestionTypeChange(index, e)}
                    >
                      {
                        questionaireType == 'quiz' ? <MenuItem value="Single-choice">Single-choice</MenuItem> : <>
                          <MenuItem value="Multiple-choice">Multiple-choice</MenuItem>
                          <MenuItem value="Single-choice">Single-choice</MenuItem>
                          <MenuItem value="Rating">Rating</MenuItem>
                          <MenuItem value="Agree-disagree">Agree-disagree</MenuItem>
                          <MenuItem value="Short-answer">Short-answer</MenuItem>
                          <MenuItem value="Brief-answer">Brief-answer</MenuItem>
                        </>
                      }
                    </Select>
                    <IconButton onClick={() => handleSaveQuestion(index)}>
                      <Typography sx={{ color: '#F84135', fontWeight: 'bold' }}>Save</Typography>
                    </IconButton>
                  </Box>
                </>
              ) : (
                <IconButton onClick={() => handleEditQuestion(index)}>
                  <ModeEditIcon sx={{ color: '#F84135' }} />{/* Edit */}
                </IconButton>
              )}
              <IconButton onClick={() => handleDeleteQuestion(index)}>
                <DeleteIcon sx={{ color: '#F84135' }} />{/* Delete */}
              </IconButton>
            </Box>
          </Box>

          {editingIndex === index ? (
            <>
              <TextField
                required={question?.required}
                fullWidth
                variant="outlined"
                value={`${question?.title}`}
                onChange={(e) => handleQuestionTextChange(index, e)}
                sx={{ marginBottom: '10px' }}
              />
            </>
          ) : (
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="body1" gutterBottom>
                {`${question?.title}   ${(question?.required) ? '***' : ''} `}
              </Typography>
              <Typography variant="body2" gutterBottom color="textSecondary">
                {`Type: ${question.type}`}
              </Typography>
              {
                question?.type == 'Brief-answer' && <Typography variant="body2" gutterBottom color="textSecondary">
                  {`Maximum Words Allowed: ${question?.ansLength||"N/A"}`}
                </Typography>
              }
            </Box>
          )}

          {/* Render Based on Question Type */}
          {(question.type === 'Multiple-choice' || question.type === 'Single-choice') && (
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {question.options.map((option, idx) => (
                <Box key={idx} sx={{ width: '50%', display: 'flex', alignItems: 'center', boxSizing: 'border-box', padding: '8px' }}>
                  {editingIndex === index ? (
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={option.value}
                      onChange={(e) => handleOptionChange(index, idx, e)}
                      sx={{ marginRight: '8px' }}
                    />
                  ) : (
                    <FormControlLabel
                      control={question.type === 'Multiple-choice' ? <Checkbox disabled /> : <Radio disabled />}
                      label={option.value}
                      sx={{ width: '100%' }}
                    />
                  )}
                  {editingIndex === index && (
                    <IconButton onClick={() => handleDeleteOption(index, idx)}>
                      <DeleteIcon sx={{ color: '#F84135' }} />
                    </IconButton>
                  )}
                </Box>
              ))}
              {editingIndex === index && (
                <Button startIcon={<AddIcon />} onClick={() => handleAddOption(index)} sx={{ marginTop: '8px' }}>
                  Add Option
                </Button>
              )}
            </Box>
          )}

          {question.type === 'Rating' && (
            <RadioGroup row>
              {[1, 2, 3, 4, 5].map((value) => (
                <FormControlLabel
                  key={value}
                  control={<Radio disabled />}
                  label={value}
                />
              ))}
            </RadioGroup>
          )}

          {question.type === 'Agree-disagree' && (
            <RadioGroup row>
              <FormControlLabel
                control={<Radio disabled />}
                label="Agree"
              />
              <FormControlLabel
                control={<Radio disabled />}
                label="Disagree"
              />
            </RadioGroup>
          )}

          {(question.type === 'Short-answer' || question.type === 'Brief-answer') && (
            <TextField
              fullWidth
              variant="outlined"
              multiline={question.type === 'Brief-answer'}
              rows={question.type === 'Brief-answer' ? 4 : 1}
              value={question.title}
              onChange={(e) => handleQuestionTextChange(index, e)}
              disabled={editingIndex !== index}
            />
          )}

          {
            questionaireType == 'quiz' && (
              <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                {/* Attempt */}
                {editingIndex === index ? (
                  <>
                    <FormControl sx={{ marginBottom: '10px', width: '30%' }} >
                      <InputLabel id="correct-answer-label">Correct Answer</InputLabel>
                      <Select
                        labelId="correct-answer-label"
                        id="correct-answer"
                        value={question?.correctAnswer}
                        label="Correct Answer"
                        onChange={(e) => correctOptionChange(index, e)}
                      >
                        {question?.options?.map((option, idx) => (
                          <MenuItem key={idx} value={option.value}>
                            {option.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                  </>
                ) : (
                  <Box sx={{ width: '100%', display: 'flex', gap: '10px', justifyContent: 'start', alignItems: 'center' }}>
                    <Typography variant="body1" gutterBottom>
                      Correct Answer:
                    </Typography>
                    <Typography variant="body2" gutterBottom color="textSecondary">
                      {`${question?.correctAnswer}`}
                    </Typography>
                  </Box>
                )}
                {/* Marks of Question */}
                {editingIndex === index ? (
                  <>
                    <TextField


                      label="Question-Mark"
                      variant="outlined"
                      value={`${question?.questionMark}`}
                      onChange={(e) => handleQuestionMark(index, e)}
                      sx={{ marginBottom: '10px', width: '30%' }}
                    />
                  </>
                ) : (
                  <Box sx={{ width: '100%', display: 'flex', gap: '10px', justifyContent: 'start', alignItems: 'center' }}>
                    <Typography variant="body1" gutterBottom>
                      Mark:
                    </Typography>
                    <Typography variant="body2" gutterBottom color="textSecondary">
                      {`${question?.questionMark}`}
                    </Typography>
                  </Box>
                )}
                {/* Negative Marks */}
                {editingIndex === index ? (
                  <>
                    <TextField
                      label="Negative-Mark(i.e:-x)"
                      variant="outlined"
                      value={`${question?.negativeMark}`}
                      onChange={(e) => handleNegativeMarkChange(index, e)}
                      sx={{ marginBottom: '10px', width: '30%' }}
                    />
                  </>
                ) : (
                  <Box sx={{ width: '100%', display: 'flex', gap: '10px', justifyContent: 'start', alignItems: 'center' }}>
                    <Typography variant="body1" gutterBottom>
                      Negative-Marks
                    </Typography>
                    <Typography variant="body2" gutterBottom color="textSecondary">
                      {`${question?.negativeMark}`}
                    </Typography>
                  </Box>
                )}
                {/* Attempt Number */}
                {editingIndex === index ? (
                  <>
                    <TextField
                      label="attemptCount"
                      variant="outlined"
                      value={`${question?.attemptCount}`}
                      onChange={(e) => attemptCountChange(index, e)}
                      sx={{ marginBottom: '10px', width: '30%' }}
                    />
                  </>
                ) : (
                  <Box sx={{ width: '100%', display: 'flex', gap: '10px', justifyContent: 'start', alignItems: 'center' }}>
                    <Typography variant="body1" gutterBottom>
                      Attempt-Number:
                    </Typography>
                    <Typography variant="body2" gutterBottom color="textSecondary">
                      {`${question?.attemptCount}`}
                    </Typography>
                  </Box>
                )}
              </Box>
            )
          }
        </Box>
      ))}
    </Box>
  );
};

export default RenderQuestions;
