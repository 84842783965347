// import React, { useEffect, useState } from 'react';
// import { useNavigate, useParams } from 'react-router-dom';
// import toast from 'react-hot-toast';
// import { apiConnector } from '../../../services/ApiConnector';
// import { surveyEndpoints } from '../../../services/ApiEndPoints';
// import { useSelector } from 'react-redux';

// import RenderQuestion from '../../../components/survey/Attempting/RenderQuestion';
// import SurveyDetailCard from '../../../components/survey/Attempting/AssessmentFullDetailCard';
// import { Box, Button, CircularProgress, Typography, Modal } from '@mui/material';

// const SurveySpecific = () => {
//   const navigate = useNavigate();
//   const token = useSelector((state) => state.auth.token);
//   const [responses, setResponses] = useState({});
//   const { surveyId } = useParams();
//   const [surveyDetail, setSurveyDetail] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [timeLeft, setTimeLeft] = useState(null);
//   const [openModal, setOpenModal] = useState(true); // State to control modal visibility

//   const fetchSurveyDetail = async () => {
//     const toastId = toast.loading("Loading...");
//     setLoading(true);
//     try {
//       const response = await apiConnector(
//         "GET",
//         `${surveyEndpoints.FETCH_QUESTION_FOR_ATTEMPTING}/${surveyId}`,
//         null,
//         { Authorization: `Bearer ${token}` }
//       );
//       if (response?.data?.status) {
//         toast.success("Survey Fetched");
//         setSurveyDetail(response.data.data);
//         if (response.data.data.survey.type === 'quiz') {
//           setTimeLeft(response.data.data.survey.duration*60); // Set initial timer value
//         }
//       } else {
//         toast.error(`${response?.data?.message}!`);
//       }
//     } catch (error) {
//       toast.error(`${error?.response?.data?.message}!`);
//       console.error('Error fetching survey details:', error);
//     } finally {
//       toast.dismiss(toastId);
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchSurveyDetail();
//   }, [surveyId]);

//   useEffect(() => {
//     let timer;
//     if (timeLeft > 0) {
//       timer = setInterval(() => {
//         setTimeLeft((prev) => prev - 1);
//       }, 1000);
//     } else if (timeLeft === 0) {
//       handleSubmit();
//     }
//     return () => clearInterval(timer);
//   }, [timeLeft]);

//   const handleSubmit = async (e) => {
//     if (e) e.preventDefault(); // Prevent default if called from button click

//     const responseArr = Object.keys(responses).map(key => ({
//       questionId: key,
//       answer: responses[key]
//     }));

//     const data = {
//       surveyId,
//       responses: responseArr
//     };
//     const toastId = toast.loading("Submitting survey...");
//     try {
//       const response = await apiConnector(
//         "POST",
//         `${surveyEndpoints.SUBMIT_SURVEY}`,
//         data,
//         { Authorization: `Bearer ${token}` }
//       );
//       if (response?.data?.success) {
//         toast.success("Survey Submitted");
//         navigate('/assigned-survey');
//       } else {
//         toast.error(`${response?.data?.message}!`);
//       }
//     } catch (error) {
//       toast.error(`${error?.response?.data?.message}!`);
//       console.error('Error submitting survey:', error);
//     } finally {
//       toast.dismiss(toastId);
//     }
//   };

//   const handleProceed = () => {
//     setOpenModal(false); // Close the modal
//   };

//   const handleCancel = () => {
//     navigate('/assigned-survey'); // Navigate back
//   };

//   if (loading) {
//     return (
//       <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
//         <CircularProgress />
//       </Box>
//     );
//   }

//   return (
//     <Box 
//       component="form" 
//       onSubmit={handleSubmit} 
//       display="flex" 
//       flexDirection="column" 
//       gap={2} 
//       width="100%" 
//       mx="auto" 
//       mb={10}
//       mt={5}
//       overflow="auto" 
//       sx={{ filter: openModal ? 'blur(5px)' : 'none' }} // Blur effect on the background
//     >
//       {surveyDetail && (
//         <SurveyDetailCard
//           surveyDetail={surveyDetail?.survey}
//           questionCount={surveyDetail?.questions?.length}
//         />
//       )}

//       {/* Timer Section */}
//       {timeLeft !== null && surveyDetail?.survey?.type === 'quiz' && (
//         <Typography 
//           variant="h6" 
//           color="error" 
//           align="center" 
//           style={{
//             backgroundColor: 'white',
//             padding: 10,
//             border: '1px solid wheat',
//             position: 'fixed',
//             top: 30,
//             right: 16,
//             zIndex: 1000,
//           }}
//         >
//           Time Left: {Math.floor(timeLeft / 60)}:{(timeLeft % 60).toString().padStart(2, '0')}
//         </Typography>
//       )}

//       {surveyDetail?.questions?.map((question, index) => (
//         <Box key={question?._id} elevation={1} sx={{ p: 2 }}>
//           <RenderQuestion
//             index={index}
//             question={question}
//             responses={responses}
//             setResponses={setResponses}
//           />
//         </Box>
//       ))}

//       <Button
//         type="submit"
//         variant="contained"
//         color="error"
//         sx={{ width: '20%', mt: 5, alignSelf: 'center' }}
//       >
//         Submit Assessments
//       </Button>

//       {/* Instruction Modal */}
//       <Modal
//         open={openModal}
//         onClose={handleCancel}
//         aria-labelledby="instruction-modal-title"
//         aria-describedby="instruction-modal-description"
//       >
//         <Box 
//           sx={{
//             position: 'absolute',
//             top: '50%',
//             left: '50%',
//             height:'70vh',
//             overflowY:'scroll',
//             transform: 'translate(-50%, -50%)',
//             width: '70%',
//             bgcolor: 'background.paper',
//             boxShadow: 24,
//             p: 4,
//             borderRadius: 2,
//           }}
//         >
//           <Typography id="instruction-modal-title" variant="h6" component="h2">
//             Instructions
//           </Typography>
//           <Typography id="instruction-modal-description" sx={{ mt: 2 }}>
//             {/* Your HTML instructions go here */}
//             <div dangerouslySetInnerHTML={{ __html: surveyDetail?.survey?.Instruction }} />
//           </Typography>
//           <Box display="flex" justifyContent="space-between" mt={3}>
//             <Button variant="outlined" color="error" onClick={handleCancel}>
//               Cancel
//             </Button>
//             <Button variant="contained" color="primary" onClick={handleProceed}>
//               Proceed
//             </Button>
//           </Box>
//         </Box>
//       </Modal>
//     </Box>
//   );
// };

// export default SurveySpecific;


import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { apiConnector } from '../../../services/ApiConnector';
import { surveyEndpoints } from '../../../services/ApiEndPoints';
import { useSelector } from 'react-redux';

import RenderQuestion from '../../../components/survey/Attempting/RenderQuestion';
import SurveyDetailCard from '../../../components/survey/Attempting/AssessmentFullDetailCard';
import { Box, Button, CircularProgress, Typography, Modal } from '@mui/material';

const SurveySpecific = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const [responses, setResponses] = useState({});
  const { surveyId } = useParams();
  const [surveyDetail, setSurveyDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [timeLeft, setTimeLeft] = useState(null);
  const [openModal, setOpenModal] = useState(true); // State to control modal visibility
  const [timerStarted, setTimerStarted] = useState(false); // State to control timer start

  const fetchSurveyDetail = async () => {
    const toastId = toast.loading("Loading...");
    setLoading(true);
    try {
      const response = await apiConnector(
        "GET",
        `${surveyEndpoints.FETCH_QUESTION_FOR_ATTEMPTING}/${surveyId}`,
        null,
        { Authorization: `Bearer ${token}` }
      );
      if (response?.data?.status) {
        toast.success("Survey Fetched");
        setSurveyDetail(response.data.data);
        if (response.data.data.survey.type === 'quiz') {
          setTimeLeft(response.data.data.survey.duration * 60); // Set initial timer value
        }
      } else {
        toast.error(`${response?.data?.message}!`);
      }
    } catch (error) {
      toast.error(`${error?.response?.data?.message}!`);
      console.error('Error fetching survey details:', error);
    } finally {
      toast.dismiss(toastId);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSurveyDetail();
  }, [surveyId]);

  useEffect(() => {
    let timer;
    if (timerStarted && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prev) => prev - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      handleSubmit();
    }
    return () => clearInterval(timer);
  }, [timeLeft, timerStarted]);

  const handleSubmit = async (e) => {
    if (e) e.preventDefault(); // Prevent default if called from button click

    const responseArr = Object.keys(responses).map(key => ({
      questionId: key,
      answer: responses[key]
    }));

    const data = {
      surveyId,
      responses: responseArr
    };
    const toastId = toast.loading("Submitting survey...");
    try {
      const response = await apiConnector(
        "POST",
        `${surveyEndpoints.SUBMIT_SURVEY}`,
        data,
        { Authorization: `Bearer ${token}` }
      );
      if (response?.data?.success) {
        toast.success("Survey Submitted");
        navigate('/assigned-survey');
      } else {
        toast.error(`${response?.data?.message}!`);
      }
    } catch (error) {
      toast.error(`${error?.response?.data?.message}!`);
      console.error('Error submitting survey:', error);
    } finally {
      toast.dismiss(toastId);
    }
  };

  const handleProceed = () => {
    setOpenModal(false); // Close the modal
    setTimerStarted(true); // Start the timer
  };

  const handleCancel = () => {
    navigate('/assigned-survey'); // Navigate back
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box 
      component="form" 
      onSubmit={handleSubmit} 
      display="flex" 
      flexDirection="column" 
      gap={2} 
      width="100%" 
      mx="auto" 
      mb={10}
      mt={5}
      overflow="auto" 
      sx={{ filter: openModal ? 'blur(5px)' : 'none' }} // Blur effect on the background
    >
      {surveyDetail && (
        <SurveyDetailCard
          surveyDetail={surveyDetail?.survey}
          questionCount={surveyDetail?.questions?.length}
        />
      )}

      {/* Timer Section */}
      {timeLeft !== null && surveyDetail?.survey?.type === 'quiz' && (
        <Typography 
          variant="h6" 
          color="error" 
          align="center" 
          style={{
            backgroundColor: 'white',
            padding: 10,
            border: '1px solid wheat',
            position: 'fixed',
            top: 30,
            right: 16,
            zIndex: 1000,
          }}
        >
          Time Left: {Math.floor(timeLeft / 60)}:{(timeLeft % 60).toString().padStart(2, '0')}
        </Typography>
      )}

      {surveyDetail?.questions?.map((question, index) => (
        <Box key={question?._id} elevation={1} sx={{ p: 2 }}>
          <RenderQuestion
            index={index}
            question={question}
            responses={responses}
            setResponses={setResponses}
          />
        </Box>
      ))}

      <Button
        type="submit"
        variant="contained"
        color="error"
        sx={{ width: '20%', mt: 5, alignSelf: 'center' }}
      >
        Submit Assessments
      </Button>

      {/* Instruction Modal */}
      <Modal
        open={openModal}
        onClose={handleCancel}
        aria-labelledby="instruction-modal-title"
        aria-describedby="instruction-modal-description"
      >
        <Box 
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            height: '70vh',
            overflowY: 'scroll',
            transform: 'translate(-50%, -50%)',
            width: '70%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography id="instruction-modal-title" variant="h6" component="h2">
            Instructions
          </Typography>
          <Typography id="instruction-modal-description" sx={{ mt: 2 }}>
            {/* Your HTML instructions go here */}
            <div dangerouslySetInnerHTML={{ __html: surveyDetail?.survey?.Instruction }} />
          </Typography>
          <Box display="flex" justifyContent="space-between" mt={3}>
            <Button variant="outlined" color="error" onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={handleProceed}>
              Proceed
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default SurveySpecific;
