const BASE_URL = "https://api.snmentoring.com/api/counceller"//production
// const BASE_URL = "https://devapi.snmentoring.com/api/counceller" //Dev
// const BASE_URL = "http://localhost:8080/api/counceller" // localhost


//*************************************In Use **********************************************************

//Auth Endpoints
export const authEndpoints = {
  LOGIN_API: BASE_URL + "/sign-in",
  FORGET_PASSWORD:BASE_URL+"/forget-password",
  RESET_PASSWORD:BASE_URL+"/reset-password",
  PROFILE:BASE_URL+"/profile"
}

//Dashboard Endpoints
export const dashboardEndpoints = {
  DASHBOARD_API: BASE_URL + "/dashboard"
}


//Student Endpoints
export const studentEndpoints = {
  CREATE_STUDENT: BASE_URL + "/student/add-student",
  BULK_CREATE_STUDENT: BASE_URL + "/student/bulk-insert",
  ALL_STUDENTS_DATA_API: BASE_URL + "/student/counceller-clients",
  GET_STUDENT_DETAIL: BASE_URL + "/student/profile",
  UPDATE_STUDENT_DETAIL: BASE_URL + "/student/update",
  DELETE_STUDENT:BASE_URL+"/student/delete",
  GET_STUDENTS_BY_SCHOOL: BASE_URL + "/report/get-student-name-by-schooId"
}
//Survey endpoints
export const surveyEndpoints = {
  GET_ALL_SURVEY: BASE_URL + "/survey/counceller-survey", //Created by Counsellor
  GET_ALL_ASSIGNED_SURVEY: BASE_URL + "/survey/get-all-assign-survey", //Assigned To Counsellor
  FETCH_QUESTION_FOR_ATTEMPTING:BASE_URL+"/survey/attempt-survey",
  SUBMIT_SURVEY:BASE_URL+"/survey/submit-survey",
  CREATE_SURVEY: BASE_URL + "/survey/create-survey",
  SURVEY_DETAIL: BASE_URL + "/survey/get-survey-by",
  DELETE_SURVEY: BASE_URL + "/survey/delete-survey",
  UPDATE_SURVEY:BASE_URL+"/survey/update-survey",
  GET_SURVEY_CLIENTS:BASE_URL+"/survey/get-student-by-survey",
  REMOVE_CLIENT:BASE_URL+"/survey/remove-students",
  GET_UNENROLLED_STUDENT:BASE_URL+"/student/unassigned-students", //not assigned in the particular survey from which i am requesting
  ASSIGN_SURVEY_TO_MULTIPLE_STUDENT:BASE_URL+"/survey/assign-student",
  
  GET_ALL_SURVEYRESPONSE:BASE_URL+"/survey/survey-responses",
  GET_SURVEY_RESPONSE_DETAIL:BASE_URL+"/survey/survey-response-details", //will have id
  ADD_QUESTION: BASE_URL + "/question/add-question",
  DELETE_QUESTION: BASE_URL + "/question/delete",
  UPDATE_QUESTION: BASE_URL + "/question/update",
  EXPORT_RESPONSE:BASE_URL+"/survey/get-all-survey-responses",
  GET_LECTURE_NAME:BASE_URL+"/lecture/get-lecture"
}

// Notification endpoints ...
export const notificationEndpoints={
  ALL_NOTIFICATION_DATA_API:BASE_URL+"/notification/get-all-notifications",
  GET_NOTIFICATION_DETAIL:BASE_URL+"/notification/notification",
  CREATE_CUSTOM_NOTIFICATION:BASE_URL+"/notification/create-custom-notification",
  GET_USERS_BY_COURSEID:BASE_URL+"/notification/get-user-list"
}


export const brandEndpoints = {
  GET_ALL_BRANDS:BASE_URL+"/brand/get-all-brand",
  ADD_BRAND:BASE_URL+"/brand/create",
  GET_BRAND_DETAIL:BASE_URL+"/brand/get-brand-by-id",
  UPDATE_BRAND_DETAIL:BASE_URL+"/brand/update-brand",
  DELETE_BRAND_DETAIL:BASE_URL+"/brand/delete-brand",
  BRAND_NAME_LIST:BASE_URL+"/brand/brand-list"
}

export const uploadEnpoints={
  UPLOAD_FILE_TO_AWS:BASE_URL+"/upload-video"
}


export const reportEndpoints = {
  CREATE_REPORT: BASE_URL + "/report/add",
  ALL_REPORT_DATA_API: BASE_URL + "/report/get-created-report",
  ALL_OWN_REPORT_DATA_API:BASE_URL+"/report/get-report",
  GET_REPORT_DETAIL: BASE_URL + "/report", //probably not in use 
  UPDATE_REPORT_DETAIL: BASE_URL + "/report/update",
  DELETE_REPORT:BASE_URL+"/report/delete",
  REPORTS_OF_CLIENT:BASE_URL+"/report/get-by-student"
}


export const schoolEndpoints = {
  CREATE_SCHOOL: BASE_URL + "/school/add-school",
  ALL_SCHOOLS_DATA_API: BASE_URL + "/school/get-all-schools",
  GET_SCHOOL_DETAIL: BASE_URL + "/school/",
  UPDATE_SCHOOL_DETAIL: BASE_URL + "/school/update-school",
  GET_SCHOOL_NAME: BASE_URL + "/school/get-all-schools-name"
}


//*************************************Not In Use **********************************************************

//Schools Endpoints

export const teacherEndpoints = {
  CREATE_TEACHER: BASE_URL + "/teacher/add-teacher",
  BULK_CREATE_TEACHER: BASE_URL + "/teacher/bulk-insert",
  ALL_TEACHERS_DATA_API: BASE_URL + "/teacher/teachers",
  GET_TEACHER_DETAIL: BASE_URL + "/teacher/profile",
  UPDATE_TEACHER_DETAIL: BASE_URL + "/teacher/update",
  DELETE_TEACHER:BASE_URL+"/teacher/delete",
  GET_TEACHERS_BY_SCHOOL: BASE_URL + "/report/get-teacher-name-by-schooId",
  // Assign Student
  GET_ASSIGNED_STUDENT_LIST:BASE_URL+"/teacher/get-assign-student",
  ASSIGN_STUDENT_TO_TEACHER:BASE_URL+"/teacher/assign-student",
  GET_UNASSIGNED_STUDENT_LIST:BASE_URL+"/teacher/get-unEnrolled-student",
  UNASSIGN_STUDENT:BASE_URL+"/teacher/unassign-student"
}

export const courseEndpoints = {
  CREATE_COURSE: BASE_URL + "/course/add-course",
  ALL_COURSE_DATA_NAME: BASE_URL + "/course/course-names",
  ALL_COURSE_DATA_API: BASE_URL + "/course/get-all-course",
  GET_COURSE_DETAIL: BASE_URL + "/course/course",

  COPY_COURSE:BASE_URL+"/course/copy",
  DELETE_COURSE:BASE_URL+"/course/delete",
  UPDATE_COURSE_DETAIL: BASE_URL + "/course/update",
  COURSE_STUDENTS: BASE_URL + "/course/get-students",
  COURSE_TEACHERS: BASE_URL + "/course/get-teachers",
  COURSE_UNENROLLED_STUDENT: BASE_URL + "/course/get-unenrolled-student",
  COURSE_UNENROLLED_TEACHER: BASE_URL + "/course/get-unenrolled-teacher",
  COURSE_BULK_USER_INSERT:BASE_URL+"/course/bulk-user-insert",
  COURSE_REMOVE_USER:BASE_URL+"/course/remove-user"
}

export const lectureEndpoints = {
  CREATE_LECTURE: BASE_URL + "/lecture/add-lecture",
  UPDATE_LECTURE: BASE_URL + "/lecture/update",
  DELETE_LECTURE: BASE_URL + "/lecture/delete",
}

export const testEndpoints = {
  CREATE_TEST: BASE_URL + "/test/add-test",
  UPDATE_TEST: BASE_URL + "/test/update",
  DELETE_TEST: BASE_URL + "/test/delete",
}




export const certificationEndpoints={
  GET_ALL_CERTIFICATION:BASE_URL+"/certificate/certificate",
  GET_CERTIFICATION_DETAIL:BASE_URL+"/certificate/certificates",
  CREATE_CERTIFICATION:BASE_URL+"/certificate/certificates",
  DELETE_CERTIFICATION:BASE_URL+"/certificate/certificates",
  UPDATE_CERTIFICATION:BASE_URL+"/certificate/certificates"
}





// export const brandEndpoints = {
//     GET_ALL_BRANDS:BASE_URL+"/brand/get-all-brand",
//     ADD_BRAND:BASE_URL+"/brand/create",
//     GET_BRAND_DETAIL:BASE_URL+"/brand/get-brand-by-id",
//     UPDATE_BRAND_DETAIL:BASE_URL+"/brand/update-brand",
//     DELETE_BRAND_DETAIL:BASE_URL+"/brand/delete-brand",
//     BRAND_NAME_LIST:BASE_URL+"/brand/brand-list"
// }

export const counselorEndpoints ={
    GET_ALL_COUNSELORS:BASE_URL+"/counceller/get-counceller",
    ADD_COUNSELOR:BASE_URL+"/counceller/add-counceller",
    GET_COUNSELOR_DETAIL:BASE_URL+"/counceller",
    GET_COUNSELOR_STUDENTS:BASE_URL+"/counceller/counceller-clients",
    UPDATE_COUNSELOR_DETAIL:BASE_URL+"/counceller/update-counceller",
    UPDATE_COUNSELOR_PERMISSION:BASE_URL+"/counceller/update-permission",
    DELETE_COUNSELOR_DETAIL:BASE_URL+"/counceller/delete-counceller",
}