import { Box, Card, CardContent, Typography, Chip } from '@mui/material';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom'; // Assuming you're using react-router for navigation

const NotificationsList = ({ notifications }) => {
  const navigate = useNavigate();

  return (
    <Box>
      {notifications?.map((notification, index) => (
        <Card
          key={index}
          sx={{
            width: '100%',
            mb: 2,
            backgroundColor: notification?.isView ? 'white' : 'wheat', // Adjust background color based on isView
            position: 'relative', // To position the unread chip in the top-right corner
            ":hover": { backgroundColor: '#F5F5F5' },
            cursor: 'pointer',
          }}
          onClick={() => navigate(`/notification/${notification._id}`)}
        >
          <CardContent>
            {/* Display "Unread" tag in top-right if the notification is unread */}
            {!notification?.isView && (
              <Chip
                label="Unread"
                color="error"
                size="small"
                sx={{ position: 'absolute', top: 8, right: 8 }}
              />
            )}

            <Typography variant="h6" component="div" color={'#EF492E'}>
              {notification?.name}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 1.5 }}>
              {notification?.type}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 1.5 }}>
              {/* {format(new Date(notification?.createdAt), "MMMM d, yyyy h:mm a")} */}
              {notification?.createdAt}
            </Typography>
            <Typography variant="body1" component="div">
              {notification?.description.slice(0, 100)}...
            </Typography>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default NotificationsList;
